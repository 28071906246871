<template>
  <div class="tab-selector">
    <div class="left">
      <!-- Back button - mobile -->
      <a class="btn btn-link ps-1 pe-3"
         @click.prevent="$emit('mobile-go-back')"
         v-if="isMobile">
        <i class="fi-chevron-left fs-sm"></i>
      </a>
      <!-- / Back button - mobile -->
      <b class="top-text">{{ conversationName }}</b>
    </div>
    <div class="right">
      <!-- Tabs - Desktop -->
      <ul v-if="!isMobile" class="nav nav-tabs card-header-tabs" role="tablist">
        <li class="nav-item" v-for="(tab, i) in visibleTabs" :key="i">
          <a class="nav-link" href="#" role="tab"
             :class="{'active': isTabSelected(tab.value)}"
             @click.prevent="$emit('select-tab', tab.value)">
            {{ tab.label }}
          </a>
        </li>
      </ul>
      <!-- / Tabs - Desktop -->

      <!-- Tabs - Mobile -->
      <select v-if="isMobile"
          class="form-select" :value="selectedTab" @change="handleMobileTabSelectorChanged">
        <option v-for="(tab, i) in visibleTabs" :value="tab.value"
                :key="i">{{ tab.label }}</option>
      </select>
      <!-- / Tabs - Mobile -->

      <close-job-button v-if="!isMobile" class="ms-4" :job="job"></close-job-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CloseJobButton from "./CloseJobButton";

export default {
  name: "TabSelector",
  components: {CloseJobButton},
  props: ['selectedTab', 'job', 'conversation', 'isMobile'],
  methods: {
    isTabSelected(tab) {
      return this.selectedTab === tab;
    },

    handleMobileTabSelectorChanged(e) {
      this.$emit('select-tab', e.target.value);
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    tabs() {
      return [
        {
          label: 'Messages',
          value: 'messages',
          visible: true
        },
        {
          label: 'Files',
          value: 'files',
          visible: true
        },
        {
          label: 'Invite Colleagues',
          value: 'invite-user',
          visible: !this.conversation.closed
        },
        {
          label: 'Activity',
          value: 'activity',
          visible: true
        }
      ]
    },

    visibleTabs() {
      return _.filter(this.tabs, tab => {
        return tab.visible === true;
      });
    },

    conversationMembersToShow() {
      return _.filter(this.conversation.conversation_members, member => {
        return member.user_role_id !== this.userRole.id;
      })
    },

    conversationName() {
      let members = this.conversationMembersToShow;
      if (members.length > 1) {
        return 'Group Chat'
        // let names = _.map(members, member => {
        //   return member.user_role ? member.user_role.name : 'Unknown';
        // });
        // if (names.length > 2) {
        //   let nOthers = names.length - 2;
        //   return names.slice(0,2).join(', ') + ' and ' + nOthers + (nOthers == 1 ? ' other' : ' others')
        // }
        // return names.join(', ');
      } else {
        let firstMember = _.first(members);
        if (firstMember.user_role) {
          return firstMember.user_role.name;
        }
        return 'Unknown';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tab-selector {
  flex-shrink: 1;
  //background: #fff;
  padding: 0 15px;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #efecf3 !important;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .top-text {
      color: #1f1b2d;
    }
  }

  .right {
    min-width: 200px;
    justify-content: flex-end;
    flex-shrink: 1;
    display: flex;
    align-items: center;

    .nav-tabs {
      height: 100%;

      li {
        border-radius: 0 !important;
      }

      a {
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 0;

        padding-left: 0.5em;
        padding-right: 0.5em;

        &.active, &:hover {
          background: none;
          box-shadow: none;
        }

        &.active {
          //font-weight: 600;
          // border-bottom: 2px solid #fd5631;
          border-bottom: 2px solid rgb(var(--primary-colour));;
          //color: #454056;
        }

        &:hover {
          //color: #fd5631;
        }
      }
    }
  }
}
</style>