<template>
  <div class="invite-user-to-conversation-wrapper">
    <div class="p-3">

      <div v-if="!conversation.closed">
        <!-- Loading -->
        <div v-if="isLoading" class="text-center py-5">
          <spinner></spinner>
        </div>
        <!-- / Loading -->

        <!-- No results -->
        <no-results v-if="!isLoading && userRolesToShow.length === 0">
          Nobody else can be added to this conversation
        </no-results>
        <!-- / No results -->

        <!-- User list -->
        <user-role-card v-for="(role, i) in userRolesToShow"
                        :key="role.id"
                        class="user-role-card mb-3"
                        @selected="() => addUserRoleToConversation(role)"
                        :user-role="role"></user-role-card>
        <!-- / User list -->

        <!-- Prompt to invite colleagues to Syndesi -->
        <div v-if="userRole.is_admin && !isLoading" class="mt-3">
          <p>Want to add a colleague who isn't on Syndesi yet? <a href="/users" target="_blank">Click here to invite
            them.</a></p>
        </div>
        <!-- / Prompt to invite colleagues to Syndesi -->
      </div>

      <div v-if="conversation.closed">
        <no-results>
          Cannot invite colleagues as the conversation is closed.
        </no-results>
      </div>
    </div>
  </div>
</template>

<script>
import Forms from "../../../mixins/Forms";
import companyUserRoles from "../../../api/company-user-roles";
import Spinner from "../../../components/Spinner";
import NoResults from "../../../components/NoResults";
import UserRoleCard from "./InviteUserToConversation/UserRoleCard";
import conversations from "../../../api/conversations";
import eventBus from "../../../eventBus";

import {useToast} from "vue-toastification";

const toast = useToast();
import {mapGetters} from 'vuex';
import LoadingScreen from "../../../components/LoadingScreen";

const _ = require('lodash');

export default {
  name: "InviteUserToConversation",
  props: ['job', 'conversation'],
  mixins: [Forms],
  components: {LoadingScreen, UserRoleCard, NoResults, Spinner},
  data() {
    return {
      isLoading: false,
      isSaving: false,

      userRoles: []
    }
  },
  created() {
    this.loadUserRoles();
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    idsOfUserRolesAlreadyInConversation() {
      return _.map(this.conversation.conversation_members, 'user_role_id');
    },

    userRolesToShow() {
      let vm = this;
      return _.filter(this.userRoles, userRole => {
        return !vm.idsOfUserRolesAlreadyInConversation.includes(userRole.id)
      })
    }
  },
  methods: {
    loadUserRoles() {
      let vm = this;
      vm.isLoading = true;
      companyUserRoles.getAll().then(r => {
        vm.isLoading = false;
        vm.userRoles = r.data;
      }).catch(e => {
        vm.isLoading = false;
        vm.setAndNotifyErrors(e, 'Error loading user roles');
      })
    },

    addUserRoleToConversation(userRole) {
      let vm = this;
      vm.isSaving = true;
      conversations.addUserRoleToConversation(this.job.id, this.conversation.id, userRole.id).then(r => {
        vm.isSaving = false;
        toast.success('User added to conversation successfully');
        // alert('Need to reload here');
        eventBus.$emit('user-added-to-conversation');
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error adding user to conversation');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-user-to-conversation-wrapper {
  max-height: 100%;
  overflow-y: scroll;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>