<template>
  <div @click.prevent="$emit('selected')" class="lawyer-job-member-card border border-2 border-secondary rounded-3 p-2" :class="{'selected': isSelected, 'disabled': isDisabled}">
    <div class="left">
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="provider.user_role"
                        width="50"></user-role-avatar>
    </div>
    <div class="middle">
      <b>{{ provider.user_role.name }}</b>
    </div>
    <div class="right" v-if="!isDisabled">
      <i v-if="isSelected" class="fi-check-circle text-black"></i>
      <i v-else class="fi-x-circle text-black"></i>
    </div>
  </div>
</template>

<script>
import UserRoleAvatar from "../../../../components/UserRoleAvatar";

export default {
  name: "LawyerJobMemberToggleCard",
  props: ['provider', 'isSelected', 'isDisabled'],
  components: {UserRoleAvatar}
}
</script>

<style scoped lang="scss">
.lawyer-job-member-card {
  display: flex;
  flex-display: row;

  &:hover {
    cursor: pointer;
    background-color: #f5f4f8;
  }

  &.selected {
    background-color: #f5f4f8;
  }

  &.disabled {
    border: none !important;
    background-color: #fff;
    &:hover {
      background-color: #fff !important;
      cursor: default;
    }
  }

  .left {
    width: 70px;
    min-width: 70px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: 14px;
  }

  .right {
    width: 50px;
    flex-shrink: 1;
    padding-left: 20px;
    padding-right: 10px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>