<template>
  <div v-show="shouldShowEntry">
    <!-- Button -->
    <div class="entry bg-primary text-white" @click.prevent="openModal">
      <i class="fi-user-plus icon"></i>
      <span class="entry-title">Click here to invite your client</span>
    </div>
    <!-- / Button -->

    <!-- Modal -->
    <vue-final-modal v-model="visible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">
              Invite Your Client
            </h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">

            <loading-screen :is-loading="isSaving"></loading-screen>

            <!-- Wizard -->
            <div class="wizard-wrapper mb-4">
              <wizard-progress :current-stage="currentStage" @set-stage="setStage"></wizard-progress>
            </div>
            <!-- / Wizard -->

            <!-- Client Details -->
            <div v-if="currentStage == 0" class="px-4 mb-3">

              <!-- Client Name -->
              <div class="pt-3 mb-4">
                <label class="form-label" for="clientName">Client Name</label>
                <input :disabled="isSaving"
                       placeholder="Please enter the name of your client"
                       v-model="clientName" type="text" class="form-control" id="clientName"/>
              </div>
              <!-- / Client Name -->

              <!-- Client Email -->
              <div class="mb-4">
                <label class="form-label" for="clientEmail">Client Email</label>
                <input :disabled="isSaving"
                       placeholder="And lastly your client's email"
                       v-model="clientEmail" type="email" class="form-control" id="clientEmail"/>
              </div>
              <!-- / Client Email -->

              <!-- Next Button -->
              <div class="text-center">
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3" href="#"
                   @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
                  Next
                  <i class="fi-chevron-right fs-sm ms-2"></i>
                </a>
              </div>
              <!-- / Next Button -->
            </div>
            <!-- / Client Details -->

            <!-- Review Lawyers -->
            <div v-if="currentStage == 1" class="px-2 pt-2 mb-3 text-center">
              <p class="fw-bold">
                By default we'll present all professionals to your client.
                <br>
                Untick any that you wish to remove at this stage.
              </p>

              <!-- Selector -->
              <div class="row pt-1">
                <div class="col-md-6"
                     v-for="(provider, i) in acceptedLawyers" :key="i">
                  <lawyer-job-member-toggle-card
                      class="mb-2"
                      :provider="provider"
                      :is-selected="isProviderJobMemberSelected(provider)"
                      @selected="toggleProviderJobMemberSelected(provider.id)"
                  ></lawyer-job-member-toggle-card>
                </div>
              </div>
              <!-- / Selector -->

              <!-- Not accepted -->
              <div class="pt-5" v-if="pendingLawyers.length">
                <p>
                  The following professionals have not accepted the opportunity yet and will be removed:
                </p>
                <div class="row">
                  <div class="col-md-6"
                       v-for="(provider, i) in pendingLawyers" :key="i">
                    <lawyer-job-member-toggle-card
                        class="mb-2"
                        :provider="provider"
                        :is-disabled="true"
                    ></lawyer-job-member-toggle-card>
                  </div>
                </div>
              </div>
              <!-- / Not accepted -->

              <div class="button-footer mt-4">
                <!-- Previous Button -->
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3 me-4" href="#"
                   @click.prevent="handlePreviousButton">
                  <i class="fi-chevron-left fs-sm me-2"></i>
                  Previous
                </a>
                <!-- / Previous Button -->

                <!-- Next Button -->
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3" href="#"
                   @click.prevent="handleNextButton" :class="{'disabled': !this.canGoForward}">
                  Next
                  <i class="fi-chevron-right fs-sm ms-2"></i>
                </a>
                <!-- / Next Button -->
              </div>

            </div>
            <!-- / Review Lawyers -->

            <!-- Review -->
            <div v-if="currentStage == 2" class="px-2 pt-2 mb-3 text-center">
              <div class="row">
                <div class="col-md-6">
                  <div class="bg-secondary h-100 rounded-3 p-2 pt-4">
                    <p>
                      <b>Client Name: </b> <br>{{ clientName }}
                    </p>
                    <p>
                      <b>Client Email:</b> <br>{{ clientEmail }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="bg-secondary h-100 rounded-3 p-2 pt-4">
                    <div class="mb-2">
                      <b>Introduce the following professionals:</b>
                    </div>
                    <div v-for="jobMember in jobMembersBeingKept">
                      {{ jobMember.user_role.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="button-footer mt-4">
                <!-- Previous Button -->
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3 me-4" href="#"
                   @click.prevent="handlePreviousButton">
                  <i class="fi-chevron-left fs-sm me-2"></i>
                  Previous
                </a>
                <!-- / Previous Button -->

                <!-- Next Button -->
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3" href="#"
                   @click.prevent="sendInvite">
                  Send Invite
                </a>
                <!-- / Next Button -->
              </div>
            </div>
            <!-- / Review -->

          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- / Modal -->
  </div>
</template>

<script>
import LoadingScreen from "../../../components/LoadingScreen";
const _ = require('lodash');
import WizardProgress from "./InviteClientListEntryAndModal/WizardProgress";
import LawyerJobMemberToggleCard from "./InviteClientListEntryAndModal/LawyerJobMemberToggleCard";
import jobs from "../../../api/jobs";
import Forms from "../../../mixins/Forms";

import { useToast } from "vue-toastification";
const toast = useToast();
import eventBus from "../../../eventBus";

export default {
  name: "InviteClientListEntryAndModal",
  components: {LoadingScreen, LawyerJobMemberToggleCard, WizardProgress},
  props: ['job'],
  mixins: [Forms],
  data() {
    return {
      invitedJobIds: [],

      visible: false,
      currentStage: 0,

      clientName: '',
      clientEmail: '',

      idsOfProviderJobMembersToKeep: [],

      isSaving: false
    }
  },
  created() {
    let vm = this;
    eventBus.$on('open-invite-client-modal', function() {
      if (vm.visible === false) {
        vm.visible = true;
      }
    });
  },
  computed: {
   shouldShowEntry() {
     if (this.job) {
       return !this.invitedJobIds.includes(this.job.id)
     }
     return false;
   },

    canGoForward() {
      if (this.currentStage == 0) {
        return (
            this.clientName && this.clientName.trim().length
            && this.clientEmail && this.clientEmail.trim().length
        );
      }
      if (this.currentStage == 1) {
        return this.idsOfProviderJobMembersToKeep.length > 0;
      }
      return false;
    },

    pendingLawyers() {
      return _.filter(this.job.job_members, jm => {
        let isPending = (jm.status == 0); // pending
        let isProvider = (jm.user_role.role == 'provider');
        let isDisqualified = jm.disqualified;
        return (isPending && isProvider && isDisqualified);
      });
    },

    acceptedLawyers() {
      return _.filter(this.job.job_members, jm => {
        let isAccepted = (jm.status == 1); // accepted
        let isProvider = (jm.user_role.role == 'provider');
        let isDisqualified = jm.disqualified;
        return (isAccepted && isProvider && !isDisqualified);
      });
    },

    jobMembersBeingKept() {
      let vm = this;
      return _.filter(vm.job.job_members, jm => {
        return vm.idsOfProviderJobMembersToKeep.includes(jm.id);
      });
    }
  },
  methods: {
    openModal() {
      this.visible = true;
    },

    closeModal() {
      this.visible = false;
    },

    setStage(i) {
      this.currentStage = i;
    },

    handlePreviousButton() {
      this.currentStage -= 1;
    },

    handleNextButton() {
      if (!this.canGoForward) {
        return false;
      }
      this.currentStage += 1;
      if (
          this.currentStage == 1
          && this.idsOfProviderJobMembersToKeep.length == 0
      ) {
        this.idsOfProviderJobMembersToKeep = _.map(this.acceptedLawyers, 'id');
      }
    },

    isProviderJobMemberSelected(providerJobMember) {
      return this.idsOfProviderJobMembersToKeep.includes(providerJobMember.id);
    },

    toggleProviderJobMemberSelected(id) {
      this.idsOfProviderJobMembersToKeep = _.xor(this.idsOfProviderJobMembersToKeep, [id]);
    },

    sendInvite() {
      let vm = this;
      vm.isSaving = true;
      jobs.inviteClient(this.job.id, {
        client_name: this.clientName,
        client_email: this.clientEmail,
        provider_job_members_to_keep: this.idsOfProviderJobMembersToKeep
      }).then(() => {
        vm.invitedJobIds.push(vm.job.id);
        vm.visible = false;
        toast.success('Client invited successfully');
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e);
      })
    }
  }
}
</script>

<style scoped lang="scss">

.entry {
  word-break: break-word;
  //border: 1px solid #efecf3 !important;
  padding: 12px;
  border-radius: 10px;
  //margin-bottom: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 19px;
  }

  .entry-title {
    font-weight: 550;
    font-size: 15px;
    margin-left: 15px;
  }
}
</style>