<template>
  <div class="d-inline">
    <a href="#" @click.prevent="openModal">
      <i class="fi-info-circle opacity-80"></i>
    </a>

    <vue-final-modal v-model="showModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">
              You Are Talking To
            </h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">

            <div class="row">
              <div class="col-md-6" v-for="(member, i) in conversationMembersToShow">
                <job-member-card
                    :member="member"
                    :key="i"
                    class="mb-3"></job-member-card>
              </div>
            </div>

          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import JobMemberCard from "../../JobDetailsSection/JobMemberCard";
import {mapGetters} from "vuex";
export default {
  name: "ConversationMembersButtonAndModal",
  components: {JobMemberCard},
  props: ['conversation'],
  data() {
    return {
      showModal: false
    }
  },
  computed: {
   ...mapGetters('auth', {
     userRole: 'userRole'
   }),

    conversationMembersToShow() {
      return _.filter(this.conversation.conversation_members, member => {
        return member.user_role_id !== this.userRole.id;
      });
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style scoped>

</style>