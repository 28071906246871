<template>
  <div class="card user-role-card bg-white rounded-3 p-3 py-3 card-hover" @click.prevent="$emit('selected', userRole)">
    <div class="left">
<!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(userRole)" width="80" :alt="userRole.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="userRole" width="80"></user-role-avatar>
    </div>
    <div class="middle">
      <b>{{ userRole.name }}</b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ userRole.bio }}
      </p>
    </div>
    <div class="right">
    </div>
  </div>
</template>

<script>
import Images from "../../../mixins/Images";
import Permissions from "../../../mixins/Permissions";
import UserRoleAvatar from "../../../components/UserRoleAvatar";

export default {
  name: "JobMemberCard",
  components: {UserRoleAvatar},
  props: ['member'],
  mixins: [Images, Permissions],
  computed: {
    userRole() {
      return this.member.user_role;
    }
  }
}
</script>

<style scoped lang="scss">
.user-role-card {
  display: flex;
  flex-direction: row;

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>