<template>
  <div class="status-tabs">
    <!-- Tabs - Desktop -->
    <ul class="nav nav-tabs card-header-tabs" role="tablist">
      <!--      <li class="nav-item">-->
      <!--        <a class="nav-link" @click.prevent="$emit('set-status', null)"-->
      <!--           :class="{'active': isTabSelected(null)}"-->
      <!--           href="#" role="tab">-->
      <!--          All-->
      <!--        </a>-->
      <!--      </li>-->
      <li class="nav-item" v-for="(status, i) in jobStatusOptions" :key="i">
        <a class="nav-link" href="#" role="tab"
           :class="{'active': isTabSelected(status.value)}"
           @click.prevent="$emit('set-status', status.value)">
          {{ formatStatusLabel(status.label) }}
          <span class="ms-1">({{ unreadCountByStatus[status.value] }})</span>
        </a>
      </li>
    </ul>
    <!-- / Tabs - Desktop -->
  </div>
</template>

<script>
import Jobs from "../../../mixins/Jobs";
import {mapGetters} from "vuex";

export default {
  name: "StatusTabs",
  props: ['status'],
  mixins: [Jobs],
  methods: {
    isTabSelected(value) {
      return this.status === value;
    },

    formatStatusLabel(label) {
      if (label == 'Completed') {
        return 'Complete';
      }
      return label;
    }
  },
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider',
      isClient: 'isClient',
      userRole: 'userRole'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),

    ...mapGetters('conversations', {
      conversations: 'conversations'
    }),

    jobsWeCareAbout() {
      if (this.jobs && this.jobs.length) {
        let jobs = _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          // return jobMembership.status == 1; // Accepted jobs only

          // If job is quoting, show pending && accepted jobs
          if (job.status == 0) {
            return jobMembership.status == 0 || jobMembership.status == 1;
          }

          // Otherwise show only accepted jobs
          return jobMembership.status == 1; // Accepted jobs only
        });

        return jobs;
      }
      return [];
    },

    unreadCountByStatus() {
      let counts = [];
      _.each(this.jobStatusOptions, option => {
        counts[option.value] = 0;
      });

      _.each(this.jobsWeCareAbout, job => {
        // Let's see if there are any unread conversations
        let enabledConversationsForJob = _.filter(this.conversations, c => (c.enabled && c.job_id === job.id));
        let unreadConversations = _.filter(enabledConversationsForJob, conversation => {
          let lastHumanActivity = conversation.last_human_activity;

          // If no last human activity, let's default it to the conversation created at date
          if (!lastHumanActivity) {
            lastHumanActivity = conversation.created_at;
          }

          // Let's now get my conversation member
          let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
            return conversationMember.user_role_id === this.userRole.id;
          });
          let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

          // If I haven't read the conversation, then it's unread if there's been LHA that isn't the convo being created
          if (!lastReadAt) {
            if (conversation.last_human_activity == conversation.created_at) {
              return false ;
            }
            return true;
          }

          // If we have both timestamps then let's compare
          let lastHumanActivityMoment = window.moment(lastHumanActivity);
          let lastReadAtMoment = window.moment(lastReadAt);

          return lastHumanActivityMoment.isAfter(lastReadAtMoment);
        });

        if (unreadConversations.length > 0) {
          counts[job.status] = counts[job.status] + 1;
        }
      });

      return counts;
    }
  }
}
</script>

<style scoped lang="scss">
.status-tabs {
  border-bottom: 1px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;

  ul {
    width: 100%;
    display: flex;

    li {
      flex-grow: 1;
      margin-right: 0 !important;
      text-align: center;
      display: flex;

      a {
        flex: 1;
        color: #fff;
        background: none;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;
        border-radius: 0;
        padding-left: 0.3em;
        padding-right: 0.3em;
        font-size: 11.5px;
        padding-top: 11px;
        padding-bottom: 12px;
        text-align: center;

        &:hover {
          color: #fff;
        }

        &.active {
          background: none;
          // border-bottom: 2px solid #fd5631;
          border-bottom: 2px solid rgb(var(--primary-colour));
        }
      }
    }
  }
}
</style>