<template>
  <div class="conversations-sidebar" :class="{'is-mobile': isMobile}">
    <div class="top">
      <!-- Back button - mobile -->
      <a class="btn btn-link ps-1 pe-3"
         @click.prevent="$emit('mobile-go-back')"
         v-if="isMobile">
        <i class="fi-chevron-left fs-sm"></i>
      </a>
      <!-- / Back button - mobile -->
      <b class="top-text">Conversations</b>
    </div>

    <!--    <h2 class="h3 pt-2 pb-2 text-center">Conversations</h2>-->

    <div class="inner">
      <!-- Loader -->
      <div class="loader" v-if="isLoading">
        <spinner></spinner>
      </div>
      <!-- / Loader -->

      <!-- Invite Client -->
      <div class="category-wrapper" v-if="isAdvisor && !areClientDetailsProvided && selectedJob">
        <invite-client-list-entry-and-modal v-if="selectedJob.status === 0"
            :job="selectedJob"></invite-client-list-entry-and-modal>
        <invite-client-to-active-job-list-entry-and-modal v-if="selectedJob.status === 1"
            :job="selectedJob"></invite-client-to-active-job-list-entry-and-modal>
      </div>
      <!-- / Invite Client -->

      <!-- Entry -->
      <div class="category-wrapper">
        <conversation-selector-entry
            :selected-conversation="selectedConversation"
            v-for="conversation in orderConversationsByNumberOfConversationMembers(conversationsWithNoProvider)"
            :conversation="conversation"
            @select-conversation="() => selectConversation(conversation)"
            :key="conversation.id"></conversation-selector-entry>
      </div>
      <!-- / Entry -->

      <!-- Category -->
      <div class="category-wrapper" v-for="(conversations, category) in conversationsGroupedByProviderCompany"
           :key="category">
        <div class="category">
          <div class="category-title">{{ category }}</div>
        </div>
        <conversation-selector-entry
            :selected-conversation="selectedConversation"
            v-for="conversation in getDirectChats(conversations)"
            :conversation="conversation"
            @select-conversation="() => selectConversation(conversation)"
            :key="conversation.id"></conversation-selector-entry>
        <group-chats-wrapper :conversations="getGroupChats(conversations)"
                             @select-conversation="(conversation) => selectConversation(conversation)"
                             :selected-conversation="selectedConversation"
        ></group-chats-wrapper>
      </div>
      <!-- / Category -->

      <!-- (Client/Advisor) - No lawyers have accepted yet -->
      <div class="no-lawyers-accepted" v-if="shouldShowNoLawyersAccepted && !isLoading">
        <!--        <spinner></spinner>-->
        <no-lawyers-accepted-carousel></no-lawyers-accepted-carousel>
      </div>
      <!-- / (Client/Advisor) - No lawyers have accepted yet -->
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner";
import ConversationSelectorEntry from "./ConversationSelector/ConversationSelectorEntry";
import {mapGetters} from 'vuex';
import NoLawyersAcceptedCarousel from "./ConversationSelector/NoLawyersAcceptedCarousel";
import GroupChatsWrapper from "./ConversationSelector/GroupChatsWrapper";
import InviteClientListEntryAndModal from "./ConversationSelector/InviteClientListEntryAndModal";
import InviteClientToActiveJobListEntryAndModal from "./ConversationSelector/InviteClientToActiveJobListEntryAndModal";

const _ = require('lodash');

export default {
  name: "ConversationSelector",
  components: {
    InviteClientToActiveJobListEntryAndModal,
    InviteClientListEntryAndModal,
    GroupChatsWrapper, NoLawyersAcceptedCarousel, ConversationSelectorEntry, Spinner},
  props: ['isLoading', 'conversations', 'selectedConversation', 'isMobile', 'selectedJob'],
  methods: {
    selectConversation(conversation) {
      this.$emit('select-conversation', conversation);
    },

    orderConversationsByNumberOfConversationMembers(conversations) {
      let conversationsWithConversationMemberCount = _.map(conversations, conversation => {
        let conversationMembers = conversation.conversation_members ? conversation.conversation_members : [];
        return {
          ...conversation,
          number_of_conversation_members: conversationMembers.length
        };
      });
      return _.orderBy(conversationsWithConversationMemberCount, 'number_of_conversation_members');
    },

    getDirectChats(conversations) {
      let withConversationMemberCount = this.orderConversationsByNumberOfConversationMembers(conversations);
      return _.filter(withConversationMemberCount, conversation => {
        return conversation.number_of_conversation_members <= 2;
      });
    },

    getGroupChats(conversations) {
      let withConversationMemberCount = this.orderConversationsByNumberOfConversationMembers(conversations);
      return _.filter(withConversationMemberCount, conversation => {
        return conversation.number_of_conversation_members > 2;
      });
    }
  },
  computed: {
    ...mapGetters('auth', {
      isAdvisor: 'isAdvisor',
      isClient: 'isClient'
    }),

    areClientDetailsProvided() {
      if (!this.selectedJob) {
        return false;
      }
      return (this.selectedJob.client_name && this.selectedJob.client_email);
    },

    conversationsWithNoProvider() {
      return _.filter(this.conversations, conversation => {
        return !conversation.provider_company_id;
      });
    },

    conversationsWithProvider() {
      return _.filter(this.conversations, conversation => {
        return conversation.provider_company_id !== null;
      });
    },

    conversationsGroupedByProviderCompany() {
      return _.groupBy(this.conversationsWithProvider, 'provider_company.name')
    },

    shouldShowNoLawyersAccepted() {
      return (this.isAdvisor || this.isClient) && this.conversationsWithProvider.length == 0 && this.selectedJob !== null;
    }
  }
}
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
  margin-top: 10px;
}

.conversations-sidebar {
  flex-shrink: 1;
  background: #fff;
  border-right: 1px solid #efecf3 !important;
  //padding-top: 75px;

  display: flex;
  flex-direction: column;

  .top {
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    border-bottom: 1px solid #efecf3 !important;
    padding-left: 14px;
    display: flex;
    align-items: center;

    .top-text {
      color: #1f1b2d;
    }
  }

  .inner {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 10px;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .category-wrapper {
    margin-bottom: 15px;
  }

  .category {
    .category-title {
      color: #1f1b2d;
    }

    //padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 5px;
    font-weight: bold;
    //text-align: center;
  }

  .no-lawyers-accepted {
    display: block;
    text-align: center;
    padding-top: 10px;

    .text {
      margin-top: 5px;
    }
  }
}
</style>