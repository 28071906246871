<template>
  <div class="card user-role-card bg-white rounded-3 p-4 py-3">
    <div class="left">
<!--      <img class="d-none d-sm-block rounded-circle" :src="getAvatarSrc(userRole)" width="80" :alt="userRole.name">-->
      <user-role-avatar class="d-none d-sm-block rounded-circle" :user-role="userRole" width="80"></user-role-avatar>
    </div>
    <div class="middle">
      <b>{{ userRole.name }}</b>
      <p class="mt-1 mb-0 pb-1 small">
        {{ userRole.bio }}
      </p>
    </div>
    <div class="right">
      <a href="#" class="btn btn-sm btn-primary" :class="{'disabled': isSaving}"
         @click.prevent="handleSelected">
        <i v-if="!isSaving" class="fi-plus me-2"></i>
        <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
        {{ isSaving ? 'Adding to conversation' : 'Add to conversation' }}
      </a>
    </div>
  </div>
</template>

<script>
import Images from "../../../../mixins/Images";
import Permissions from "../../../../mixins/Permissions";
import Spinner from "../../../../components/Spinner";
import UserRoleAvatar from "../../../../components/UserRoleAvatar";

export default {
  name: "UserRoleCard",
  props: ['userRole'],
  components: {Spinner, UserRoleAvatar},
  mixins: [Images, Permissions],
  data() {
    return {
      isSaving: false
    }
  },
  computed: {},
  methods: {
    handleSelected() {
      this.isSaving = true;
      this.$emit('selected', this.userRole);
    }
  }
}
</script>

<style scoped lang="scss">
//.user-role-card:hover {
//  cursor: pointer;
//}

.user-role-card {
  display: flex;
  flex-direction: row;

  .left {
    width: 100px;
    min-width: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>