import axios from "axios";

export default {
    getStatusUpdateOptions() {
        return axios.get(window.API_BASE + '/status-update-options');
    },

    getDetailsByUpdateCode(updateCode) {
        return axios.get(window.API_BASE + '/status-updates/' + updateCode);
    },

    createUpdateFromUpdateCode(updateCode, tone, description) {
        return axios.post(window.API_BASE + '/status-updates/' + updateCode, {
            tone: tone,
            description: description
        });
    },

    createAdHocUpdate(jobId, tone, description) {
        return axios.post(window.API_BASE + '/status-updates', {
            job_id: jobId,
            tone: tone,
            description: description
        });
    }
}