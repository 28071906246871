<template>
  <div class="entry" :class="{'active': active}"
       @click.prevent="selectJob">

    <div class="entry-title">{{ job.title }}</div>
    <div class="entry-description">{{ getStatusLabel(job.status) }}</div>

    <div class="timestamp-wrapper">
      <div class="left">
        <div class="entry-timestamp" :key="timeAgoRefreshKey">{{
            displayTimeAgo(job.last_activity_for_my_user_role)
          }}
        </div>
      </div>
      <div class="right">
        <span v-if="doesJobHaveUnreadConversations && !showSubmenu"
              class="badge bg-secondary">Unread</span>
      </div>
    </div>


    <!-- Submenu -->
    <div class="icon-submenu" :key="submenuKey"
         v-if="showSubmenu">
      <div
          class="icon-submenu-option"
          v-tooltip="option.label"
          @click.prevent="selectSection(option.value)"
          :class="{'selected': isSectionSelected(option.value), 'marked': option.marked}"
          v-for="(option, i) in submenuOptions"
          :key="getSubmenuOptionKey(i)">
        <i :class="option.icon"></i>
      </div>
    </div>
    <!-- / Submenu -->
  </div>
</template>

<script>
import Jobs from "../../../mixins/Jobs";
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import {mapGetters} from "vuex";

const _ = require('lodash');

export default {
  name: "JobSelectorEntry",
  props: ['job', 'active', 'isMembershipAcceptedForSelectedJob', 'isMobile', 'selectedSection'],
  mixins: [Jobs, Dates, TimeAgoRefresher],
  data() {
    return {
      submenuKey: 1
    }
  },
  methods: {
    selectJob() {
      this.$emit('job-selected', this.job);
    },

    getSubmenuOptionKey(i) {
      return 'submenu-option-' + i;
    },

    selectSection(val) {
      this.$emit('select-section', val);
    },

    isSectionSelected(section) {
      if (section && this.selectedSection) {
        return section === this.selectedSection;
      }
      return false;
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isClient: 'isClient',
      isProvider: 'isProvider',
      isAdvisor: 'isAdvisor'
    }),

    ...mapGetters('conversations', {
      conversations: 'conversations'
    }),

    showSubmenu() {
      return (this.isMembershipAcceptedForSelectedJob && this.active && !this.isMobile);
    },

    doesJobHaveUnreadConversations() {
      let vm = this;
      if (this.job) {
        let hasUnreadMessages = false;

        // let conversationsForJob = _.filter(this.conversations, {job_id: this.job.id});

        let conversationsForJob = _.filter(this.conversations, conversation => {
          return (
              conversation.job_id == this.job.id
              && conversation.enabled == true
          );
        });

        _.each(conversationsForJob, conversation => {

          let lastHumanActivity = conversation.last_human_activity;
          // If no last human activity, let's default it to the conversation created at date
          if (!lastHumanActivity) {
            lastHumanActivity = conversation.created_at;
          }

          // Let's now get my conversation member
          let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
            return conversationMember.user_role_id === vm.userRole.id;
          });
          let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

          // If I haven't read the conversation, then it's unread if there is last human activity that isn't the convo being created
          if (!lastReadAt) {
            if (conversation.last_human_activity && conversation.last_human_activity !== conversation.created_at) {
              hasUnreadMessages = true;
            }
          } else {
            // If we have both timestamps then let's compare
            let lastHumanActivityMoment = window.moment(lastHumanActivity);
            let lastReadAtMoment = window.moment(lastReadAt);

            let isUnreadBecauseOfTimestamps = lastHumanActivityMoment.isAfter(lastReadAtMoment);
            if (isUnreadBecauseOfTimestamps) {
              hasUnreadMessages = true;
            }
          }
        });
        return hasUnreadMessages;
      }
      return false;
    },

    shouldQuotesIconBeMarked() {
      if (this.isClient) {
        return this.doesJobHavePendingQuotes;
      }
      if (this.isProvider) {
        return this.haveINotQuotedForJobYet;
      }
      return false;
    },

    doesJobHavePendingQuotes() {
      if (this.job && this.job.status === 0 && this.job.job_quotes) {
        return this.job.job_quotes.length > 0;
      }
      return false;
    },

    haveINotQuotedForJobYet() {
      if (this.job && this.job.status === 0 && this.job.job_quotes) {
        return this.job.job_quotes.length === 0;
      }
      return false;
    },

    submenuOptions() {
      return [
        {
          label: 'Messaging',
          value: 'messaging',
          icon: 'fi-chat-circle',
          marked: this.doesJobHaveUnreadConversations
        },
        {
          label: 'Quotes',
          value: 'quotes',
          icon: 'fi-briefcase',
          marked: this.shouldQuotesIconBeMarked
        },
        {
          label: 'Job Details',
          value: 'job-details',
          icon: 'fi-info-circle'
        }
      ];
    }
  }
}
</script>

<style scoped lang="scss">

.icon-submenu {
  margin-top: 8px;
  //margin-bottom: 5px;
  //padding-left: 15px;
  text-align: right;

  .icon-submenu-option {
    display: inline;
    margin-left: 15px;
    position: relative;

    //color: #fd5631;

    &.selected, &:hover {
      //color: #fff;
      // color: #fd5631;
      color: rgb(var(--primary-colour));
      cursor: pointer;
    }

    &.marked {
      &::after {
        content: "";
        // background: #fd5631;
        background: rgb(var(--primary-colour));
        width: 5px;
        height: 5px;
        display: inline-block;
        position: absolute;
        top: 2px;
        right: -5px;
        border-radius: 50%;
      }
    }
  }
}

.submenu {
  margin-top: 6px;
  margin-bottom: 5px;

  .submenu-option {
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 15px;
    display: flex;
    cursor: pointer;

    &.selected {
      // color: #fd5631;
      color: rgb(var(--primary-colour));
      //font-weight: bold;
    }

    .left {
      flex-shrink: 1;
      min-width: 15px;
    }

    .right {
      flex-grow: 1;
    }
  }
}

.entry {
  //border: 1px solid #efecf3 !important;
  padding: 13px 15px;
  border-radius: 10px;
  //margin-bottom: 5px;
  //color: #fff;
  color: rgba(255, 255, 255, 0.8);

  &.active, &:hover {
    //background: #efecf3 !important;
    background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    color: #fff;
    cursor: pointer;
  }

  //
  //&:hover {
  //  cursor: pointer;
  //  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
  //}

  .entry-title {
    font-weight: 600;
    font-size: 15px;
    word-break: break-word;
  }

  .entry-description {
    margin-top: 5px;
    font-size: 14px;
  }

  .timestamp-wrapper {
    margin-top: 5px;
    font-size: 14px;

    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      padding-left: 5px;
      text-align: right;
      .badge {
        font-size: 11px;
      }
    }
  }

  .entry-flex-wrapper {
    display: flex;
    align-items: center;

    .left {
      flex-shrink: 1;
    }

    .right {
      flex-grow: 1;
      padding-left: 15px;
    }
  }
}
</style>