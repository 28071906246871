<template>
  <div class="quotes-section">
    <div class="top">
      <div class="left">
        <!-- Back button - mobile -->
        <a class="btn btn-link ps-1 pe-3"
           @click.prevent="$emit('mobile-go-back')"
           v-if="isMobile">
          <i class="fi-chevron-left fs-sm"></i>
        </a>
        <!-- / Back button - mobile -->
        <b class="top-text">Quotes</b>
      </div>
      <div class="right">
        <div class="actions">
          <button v-if="isProvider && view === 'index' && selectedJob.status === 0"
                  @click="provideQuote" type="button" class="btn btn-sm btn-primary">
            <i class="fi-edit me-2"></i>
            Provide Quote
          </button>

          <!--          <button v-if="isProvider && view === 'index'-->
          <!--           && selectedJob.status === 1"-->
          <!--                  @click="closeJob"-->
          <!--                  type="button" class="btn btn-sm btn-primary">-->
          <!--            <i class="fi-check me-2"></i>-->
          <!--            Close Job-->
          <!--          </button>-->
          <close-job-button :job="selectedJob" v-if="view == 'index'"></close-job-button>

          <button v-if="isProvider && view === 'create'"
                  @click="backToIndex" type="button" class="btn btn-sm btn-primary">
            <i class="fi-arrow-back me-2"></i>
            Back to all quotes
          </button>
        </div>
      </div>
    </div>
    <div class="inner p-3">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <spinner></spinner>
      </div>
      <!-- / Loading -->

      <!-- Index -->
      <div v-if="!isLoading && view == 'index'">
        <no-results v-if="!allQuotes || allQuotes.length === 0" class="bg-tertiary">
          <div v-if="isProvider">
            You've not quoted for this job yet, <a href="#" @click.prevent="provideQuote">provide a quote or two now</a>.
          </div>
          <div v-if="isClient">
            You've not had any quotes yet, <a href="#" @click.prevent="goToMessaging">chat to your prospective professionals
            to make sure they have the information they need</a>.
          </div>
          <div v-if="isAdvisor">
            No quotes to show
          </div>
        </no-results>

        <div class="row">
          <div class="col-md-6" v-for="quote in acceptedQuotes" :key="quote.id">
            <job-quote-card class="mb-3"
                            @quote-accepted="() => handleQuoteAccepted(quote)"
                            :job="selectedJob" :quote="quote">
            </job-quote-card>
          </div>
          <div class="col-md-6" v-for="quote in otherQuotesSorted" :key="quote.id">
            <job-quote-card class="mb-3"
                            @discuss-quote="() => discussQuote(quote)"
                            @quote-accepted="() => handleQuoteAccepted(quote)"
                            :job="selectedJob" :quote="quote">
            </job-quote-card>
          </div>
        </div>
      </div>
      <!-- / Index -->

      <!-- Create -->
      <div v-if="!isLoading && view === 'create'">
        <provide-quote-form @quote-submitted="handleQuoteSubmitted"
                            @chat-to-client="() => chatToClient()"
                            :job="selectedJob"></provide-quote-form>
      </div>
      <!-- / Create -->
    </div>
  </div>
</template>

<script>
import JobQuoteCard from "../Jobs/Quotes/QuotesProvidedList/JobQuoteCard";
import Spinner from "../../components/Spinner";
import Forms from "../../mixins/Forms";
import NoResults from "../../components/NoResults";
import {mapGetters} from "vuex";
import ProvideQuoteForm from "../Jobs/Quotes/ProvideQuoteForm";
import CloseJobButton from "./MessagingSection/CloseJobButton";
const _ = require('lodash');

export default {
  name: "QuotesProvidedList",
  components: {CloseJobButton, ProvideQuoteForm, NoResults, JobQuoteCard, Spinner},
  props: ['selectedJob', 'isMobile'],
  mixins: [Forms],
  data() {
    return {
      serverErrors: [],
      isLoading: false,
      // allQuotes: [],

      view: 'index' // index or create
    }
  },
  created() {
    // this.loadQuotes();
  },
  methods: {
    // loadQuotes() {
    //   let vm = this;
    //   vm.isLoading = true;
    //   vm.serverErrors = [];
    //   vm.allQuotes = [];
    //   jobs.getQuotes(this.selectedJob.id).then(r => {
    //     vm.allQuotes = r.data;
    //     vm.isLoading = false;
    //   }).catch(e => {
    //     console.log(e);
    //     vm.setAndNotifyErrors(e, 'Error loading quotes');
    //   });
    // },

    goToMessaging() {
      this.$emit('go-to-messaging');
    },

    discussQuote(quote) {
      this.$emit('discuss-quote', quote)
    },

    chatToClient() {
      this.$emit('chat-to-client');
    },

    handleQuoteAccepted(quote) {
      this.$emit('quote-accepted', quote);
      // this.loadQuotes();
    },

    provideQuote() {
      // this.$router.push('/jobs/' + this.selectedJob.id + '/quotes/create');
      this.view = 'create';
    },

    handleQuoteSubmitted() {
      this.view = 'index';
      // this.loadQuotes();
    },

    backToIndex() {
      this.view = 'index';
    }
  },
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider',
      isClient: 'isClient',
      isAdvisor: 'isAdvisor'
    }),

    allQuotes() {
      let uniq = _.uniqBy(this.selectedJob.job_quotes, 'id');
      return _.filter(uniq, jq => {
        return !jq.hidden;
      });
    },

    acceptedQuotes() {
      let quotes = [];
      if (this.selectedJob.accepted_job_quote_id) {
        quotes.push(
            _.find(this.allQuotes, {id: this.selectedJob.accepted_job_quote_id})
        );
      }
      return quotes;
    },

    otherQuotes() {
      if (this.selectedJob.accepted_job_quote_id) {
        return _.filter(this.allQuotes, q => {
          return q.id !== this.selectedJob.accepted_job_quote_id;
        });
      }
      return this.allQuotes;
    },

    otherQuotesSorted() {
      return _.sortBy(this.otherQuotes, 'created_at');
    }
  }
}
</script>

<style scoped lang="scss">
.quotes-section {
  flex-grow: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;

  .top {
    background: #fff;
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    flex-shrink: 1;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: 1px solid #efecf3 !important;

    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    .top-text {
      color: #1f1b2d;
    }
  }

  .inner {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 10px;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.loader {
  text-align: center;
  margin-top: 30px;
}


.section-header {
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}
</style>