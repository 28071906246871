<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <p v-if="!isEditing">
          {{ date ? formatDate(date) : 'Not provided' }}

          <a v-if="canEdit"
             @click.prevent="edit" class="d-inline ms-2 py-0" href="#"
          ><i class="fi-edit"></i
          ></a>
        </p>
        <div v-if="isEditing" class="edit-wrapper">
          <div class="left">
            <input :disabled="isSaving"
                   v-model="dateEditing" class="form-control d-inline" type="date">
          </div>
          <div class="right">
            <button
                type="button"
                class="d-inline btn btn-success ms-2"
                :disabled="isSaving"
                @click.prevent="save"
            >
              <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
              {{ isSaving ? 'Saving' : 'Save' }}
            </button>
            <button
                type="button"
                class="d-inline btn btn-danger ms-2"
                :disabled="isSaving"
                @click.prevent="cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../../../components/Spinner";
import Dates from "../../../mixins/Dates";
import jobs from "../../../api/jobs";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

const toast = useToast();
export default {
  name: "EstimatedCompletionDateField",
  props: ['job'],
  mixins: [Dates],
  components: {Spinner},
  data() {
    return {
      isEditing: false,
      date: null,
      dateEditing: null,
      isSaving: false
    }
  },
  created() {
    this.date = this.job.estimated_completion_date ? window.moment(this.job.estimated_completion_date) : null;
  },
  watch: {
    job(newJob) {
      this.date = newJob.estimated_completion_date ? window.moment(newJob.estimated_completion_date) : null;
    }
  },
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider'
    }),

    canEdit() {
      return (this.job.status !== 2) && (this.isProvider); // completed
    }
  },
  methods: {
    edit() {
      this.isEditing = true;
      this.dateEditing = this.date ? window.moment(this.date).format('YYYY-MM-DD') : null;
    },
    cancel() {
      this.isEditing = false;
      this.dateEditing = true;
    },
    save() {
      let vm = this;
      vm.isSaving = true;
      jobs.updateEstimatedCompletionDate(vm.job.id, vm.dateEditing).then(r => {
        vm.isSaving = false;
        vm.date = window.moment(vm.dateEditing);
        vm.dateEditing = null;
        vm.isEditing = false;
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        toast.error('Error updating completion date');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-wrapper {
  display: flex;
  margin-bottom: 25px;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}
</style>