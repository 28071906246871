<template>
  <div v-show="shouldShowEntry">
    <!-- Button -->
    <div class="entry bg-primary text-white" @click.prevent="openModal">
      <i class="fi-user-plus icon"></i>
      <span class="entry-title">Click here to invite your client</span>
    </div>
    <!-- / Button -->

    <!-- Modal -->
    <vue-final-modal v-model="visible">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">
              Invite Your Client
            </h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">

            <loading-screen :is-loading="isSaving"></loading-screen>

            <!-- Client Details -->
            <div class="px-4 mb-3">

              <!-- Client Name -->
              <div class="pt-3 mb-4">
                <label class="form-label" for="clientName">Client Name</label>
                <input :disabled="isSaving"
                       placeholder="Please enter the name of your client"
                       v-model="clientName" type="text" class="form-control" id="clientName"/>
              </div>
              <!-- / Client Name -->

              <!-- Client Email -->
              <div class="mb-4">
                <label class="form-label" for="clientEmail">Client Email</label>
                <input :disabled="isSaving"
                       placeholder="And lastly your client's email"
                       v-model="clientEmail" type="email" class="form-control" id="clientEmail"/>
              </div>
              <!-- / Client Email -->

              <!-- Invite Button -->
              <div class="text-center">
                <a class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3" href="#"
                   @click.prevent="sendInvite" :class="{'disabled': !canInvite}">
                  Invite
                </a>
              </div>
              <!-- / Invite Button -->
            </div>
            <!-- / Client Details -->
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- / Modal -->
  </div>
</template>

<script>
import LoadingScreen from "../../../components/LoadingScreen";

const _ = require('lodash');
import jobs from "../../../api/jobs";
import Forms from "../../../mixins/Forms";

import {useToast} from "vue-toastification";
import eventBus from "../../../eventBus";

const toast = useToast();

export default {
  name: "InviteClientToActiveJobListEntryAndModal",
  components: {LoadingScreen},
  props: ['job'],
  mixins: [Forms],
  data() {
    return {
      invitedJobIds: [],

      visible: false,
      currentStage: 0,

      clientName: '',
      clientEmail: '',

      idsOfProviderJobMembersToKeep: [],

      isSaving: false
    }
  },
  created() {
    let vm = this;
    eventBus.$on('open-invite-client-modal', function () {
      if (vm.visible === false) {
        vm.visible = true;
      }
    });
  },
  computed: {
    shouldShowEntry() {
      if (this.job) {
        return !this.invitedJobIds.includes(this.job.id)
      }
      return false;
    },

    canInvite() {
      return (this.clientName.trim().length && this.clientEmail.trim().length);
    },

    acceptedLawyers() {
      return _.filter(this.job.job_members, jm => {
        let isAccepted = (jm.status == 1); // accepted
        let isProvider = (jm.user_role.role == 'provider');
        let isDisqualified = jm.disqualified;
        return (isAccepted && isProvider && !isDisqualified);
      });
    }
  },
  methods: {
    openModal() {
      this.visible = true;
    },

    closeModal() {
      this.visible = false;
    },

    sendInvite() {
      if (!this.canInvite) {
        return false;
      }
      let vm = this;
      vm.isSaving = true;
      jobs.inviteClient(this.job.id, {
        client_name: this.clientName,
        client_email: this.clientEmail,
        provider_job_members_to_keep: _.map(this.acceptedLawyers, 'id'),
        should_hide_all_quotes_but_accepted: true
      }).then(() => {
        vm.invitedJobIds.push(vm.job.id);
        vm.visible = false;
        toast.success('Client invited successfully');
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e);
      })
    }
  }
}
</script>

<style scoped lang="scss">

.entry {
  word-break: break-word;
  //border: 1px solid #efecf3 !important;
  padding: 12px;
  border-radius: 10px;
  //margin-bottom: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 19px;
  }

  .entry-title {
    font-weight: 550;
    font-size: 15px;
    margin-left: 15px;
  }
}
</style>