<template>
  <div class="conversation-wrapper" :class="{'is-mobile': isMobile}">

    <tab-selector @select-tab="selectTab"
                  :conversation="selectedConversation"
                  @mobile-go-back="$emit('mobile-go-back')"
                  :job="selectedJob"
                  :is-mobile="isMobile"
                  :selected-tab="selectedTab"></tab-selector>

    <who-am-i-talking-to :is-mobile="isMobile" :job="selectedJob"
                         :conversation="selectedConversation"></who-am-i-talking-to>

    <needs-to-quote-banner v-if="!isMobile && needsToQuote"
                           @navigate-to-quotes="() => $emit('navigate-to-quotes')"
                           :is-mobile="isMobile" :job="selectedJob"></needs-to-quote-banner>

    <needs-to-add-client-banner v-if="!isMobile && needsToAddClient"
                                :is-mobile="isMobile" :job="selectedJob"></needs-to-add-client-banner>

    <conversation-messages v-if="selectedTab === 'messages'"
                           :showing-needs-to-quote-banner="!isMobile && needsToQuote"
                           @navigate-to-quotes="() => $emit('navigate-to-quotes')"
                           @navigate-to-job-details="() => $emit('navigate-to-job-details')"
                           :job="selectedJob" :conversation="selectedConversation"></conversation-messages>

    <conversation-files v-if="selectedTab === 'files'"
                        @upload-first-file="attemptUploadFirstFile"
                        :job="selectedJob" :conversation="selectedConversation"></conversation-files>

    <invite-user-to-conversation v-if="selectedTab === 'invite-user'"
                                 :job="selectedJob" :conversation="selectedConversation"
    ></invite-user-to-conversation>

    <activity v-if="selectedTab === 'activity'"
              :job="selectedJob"></activity>

  </div>
</template>

<script>
import Message from "../Jobs/Messaging/JobMessaging/ConversationMessages/Message";
import TabSelector from "./MessagingSection/TabSelector";
import ConversationMessages from "./MessagingSection/ConversationMessages";
import ConversationFiles from "./MessagingSection/ConversationFiles";
import Activity from './MessagingSection/Activity'
import InviteUserToConversation from "./MessagingSection/InviteUserToConversation";
import eventBus from "../../eventBus";
import WhoAmITalkingTo from "./MessagingSection/WhoAmITalkingTo";
import NeedsToQuoteBanner from "./MessagingSection/NeedsToQuoteBanner";
import NeedsToAddClientBanner from "./MessagingSection/NeedsToAddClientBanner";
import {mapGetters} from "vuex";

export default {
  name: "MessagingSection",
  props: ['selectedConversation', 'selectedJob', 'isMobile'],
  components: {
    NeedsToQuoteBanner,
    WhoAmITalkingTo,
    InviteUserToConversation, Activity, ConversationFiles, ConversationMessages, TabSelector, Message,
    NeedsToAddClientBanner
  },
  data() {
    return {
      selectedTab: 'messages'
    }
  },
  watch: {
    selectedConversation() {
      this.selectedTab = 'messages';
    }
  },
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider',
      isAdvisor: 'isAdvisor'
    }),

    needsToQuote() {
      if (
          this.isProvider
          && this.selectedJob
          && this.selectedJob.status === 0
          && this.selectedJob.job_quotes
          && this.selectedJob.job_quotes.length === 0
      ) {
        return true;
      }
      return false;
    },

    needsToAddClient() {
      if (
          this.isAdvisor
          && this.selectedJob
          && !(this.selectedJob.client_name && this.selectedJob.client_email)
      ) {
        return true;
      }
      return false;
    }
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },

    attemptUploadFirstFile() {
      this.selectedTab = 'messages';
      this.$nextTick(() => {
        eventBus.$emit('request-file-upload');
      });
    }
  }
}
</script>

<style scoped lang="scss">
.conversation-wrapper {
  max-width: 61%;
  min-width: 61%;
  width: 61%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  &.is-mobile {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}
</style>