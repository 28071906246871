<template>
  <div class="invited-to-quote">
    <!-- Top -->
    <div class="top">
      <div class="left">
        <!-- Back button - mobile -->
        <a class="btn btn-link ps-1 pe-3"
           @click.prevent="$emit('mobile-go-back')"
           v-if="isMobile">
          <i class="fi-chevron-left fs-sm"></i>
        </a>
        <!-- / Back button - mobile -->
        <b class="top-text">You've Been Invited To Quote</b>
      </div>
      <div class="right" v-if="!isMobile">
        <div v-if="isRejected">
          <button type="button" disabled
                  class="btn btn-sm btn-danger me-2">
            <i class="fi-x me-2"></i>
            Opportunity Rejected
          </button>
        </div>
        <div class="actions" v-if="!isRejected">
          <button
              :class="{'opacity-50': isSaving}"
              @click="confirmRejectOpportunity" type="button" class="btn btn-sm btn-secondary me-2">
            <i v-if="!isRejecting" class="fi-x me-2"></i>
            <spinner v-else class="spinner-border-sm me-2"></spinner>
            Reject Opportunity
          </button>
          <button
              :class="{'opacity-50': isSaving}"
              @click="acceptOpportunity" type="button" class="btn btn-sm btn-primary">
            <i v-if="!isAccepting" class="fi-check me-2"></i>
            <spinner v-else class="spinner-border-sm me-2"></spinner>
            Discuss Opportunity
          </button>
        </div>
      </div>
    </div>
    <!-- / Top -->

    <!-- Inner -->
    <div class="inner p-3">
      <!-- Details -->

      <div class="mb-4">
        <h3 class="h6 mb-1">Client:</h3>
        <p>
          {{ selectedJob.client_user_role ? selectedJob.client_user_role.name : '' }}
          <span v-if="selectedJob.client_user_role && selectedJob.client_user_role.company_name">({{
              selectedJob.client_user_role.company_name
            }})</span>
        </p>
      </div>

      <h3 class="h6 mb-1">Description:</h3>
      <p>
        {{ selectedJob.description }}
      </p>

      <!-- / Details -->

      <!-- Job Members -->
      <h3 class="h6 mb-2">Job Members</h3>

      <div class="row">
        <div class="col-md-6" v-for="(member, i) in membersToShow">
          <job-member-card
              :member="member"
              :key="i"
              class="mb-3"></job-member-card>
        </div>
      </div>
      <!-- / Job Members -->

      <button
          :class="{'opacity-50': isSaving, 'me-3': !isMobile, 'w-100 mb-3': isMobile}"
          @click="acceptOpportunity" type="button" class="btn btn mt-2 btn-primary">
        <i v-if="!isAccepting" class="fi-chat-circle me-2"></i>
        <spinner v-else class="spinner-border-sm me-2"></spinner>
        Discuss And Quote
      </button>

      <button
          :class="{'opacity-50': isSaving, 'w-100': isMobile}"
          @click="confirmRejectOpportunity" type="button" class="btn btn mt-2 btn-secondary">
        <i v-if="!isRejecting" class="fi-x me-2"></i>
        <spinner v-else class="spinner-border-sm me-2"></spinner>
        Reject Opportunity
      </button>
    </div>
    <!-- / Inner -->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import JobMemberCard from "./JobDetailsSection/JobMemberCard";
import axios from "axios";
import Forms from "../../mixins/Forms";
import Jobs from "../../mixins/Jobs";
import JobMemberships from "../../mixins/JobMemberships";
import {useToast} from "vue-toastification";

const toast = useToast();
import Spinner from "../../components/Spinner";

export default {
  name: "InvitedToQuote",
  props: ['selectedJob', 'isMobile'],
  mixins: [Forms, JobMemberships, Jobs],
  components: {JobMemberCard, Spinner},
  data() {
    return {
      isAccepting: false,
      isRejecting: false,
      serverErrors: [],
      rejectedLocally: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isProvider: 'isProvider'
    }),

    isSaving() {
      return (this.isAccepting || this.isRejecting);
    },

    jobMembership() {
      return _.find(this.selectedJob.job_members, {user_role_id: this.userRole.id});
    },

    isRejected() {
      if (this.rejectedLocally) {
        return true;
      }
      if (this.jobMembership) {
        return this.isJobRejected(this.selectedJob, this.jobMembership);
      }
      return false;
    },

    membersToShow() {
      if (this.selectedJob) {
        return _.filter(this.selectedJob.job_members, member => {
          // Hide if disqualified from job
          if (member.disqualified) {
            return false;
          }

          // If provider, hide other providers
          if (this.isProvider && member.user_role) {
            let myUserRoleId = this.userRole.id;
            if (
                member.user_role.role === 'provider'
                && member.user_role.id !== myUserRoleId
            ) {
              return false;
            }
          }

          return true;
        })
      }
      return [];
    }
  },
  methods: {
    acceptOpportunity() {
      if (this.isSaving) {
        return false;
      }
      this.isAccepting = true;
      this.updateJobMembershipStatus(
          _.find(this.jobMembershipStatuses, {label: 'Accepted'}).value
      );
    },

    confirmRejectOpportunity() {
        let vm = this;
        swal({
          title: "Are you sure you want to reject this opportunity?",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
            confirm: "Reject Opportunity"
          }
        }).then((shouldReject) => {
          if (shouldReject) {
            vm.rejectOpportunity();
          }
        });
    },

    rejectOpportunity() {
      if (this.isSaving) {
        return false;
      }
      this.isRejecting = true;
      this.updateJobMembershipStatus(
          _.find(this.jobMembershipStatuses, {label: 'Rejected'}).value
      );
    },

    updateJobMembershipStatus(status) {
      let vm = this;
      vm.serverErrors = [];
      axios.put(window.API_BASE + '/jobs/' + this.selectedJob.id + '/membership', {
        status
      }).then(r => {
        if (this.isAccepting) {
          toast.success('Job accepted successfully');
        }
        if (this.isRejecting) {
          toast.success('Job rejected successfully');
          vm.rejectedLocally = true;
        }
        vm.isAccepting = false;
        vm.isRejecting = false;
      }).catch(e => {
        vm.isAccepting = false;
        vm.isRejecting = false;
        vm.setAndNotifyErrors(e, 'An error occurred. Please try again.');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.invited-to-quote {
  flex-grow: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;

  .top {
    background: #fff;
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    flex-shrink: 1;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: 1px solid #efecf3 !important;

    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    .top-text {
      color: #1f1b2d;
    }
  }

  .inner {
    padding: 10px;
    flex-grow: 1;
    overflow-y: scroll;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.section-header {
  margin-bottom: 30px;
}
</style>