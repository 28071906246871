<template>
  <button v-if="isProvider
           && job.status === 1"
          @click="closeJob"
          type="button" class="btn btn-sm btn-primary">
    <i class="fi-check me-2"></i>
    Close Job
  </button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CloseJobButton",
  props: ['job'],
  computed: {
    ...mapGetters('auth', {
      isProvider: 'isProvider'
    })
  },
  methods: {
    closeJob() {
      this.$router.push('/jobs/' + this.job.id + '/close');
    }
  }
}
</script>

<style scoped>

</style>