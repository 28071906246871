<template>
  <div class="job-details-section">
    <div class="top">
      <div class="left">
        <!-- Back button - mobile -->
        <a class="btn btn-link ps-1 pe-3"
           @click.prevent="$emit('mobile-go-back')"
           v-if="isMobile">
          <i class="fi-chevron-left fs-sm"></i>
        </a>
        <!-- / Back button - mobile -->
        <b class="top-text">Job Details</b>
      </div>
      <div class="right">
        <close-job-button :job="selectedJob"></close-job-button>
      </div>
    </div>

    <div class="inner p-3">
      <!-- Details -->
      <div  class="mb-4">
        <div v-if="!isClient">
          <h3 class="h6 mb-1">Client:</h3>
          <p>
            {{ selectedJob.client_user_role ? selectedJob.client_user_role.name : '-' }}
            <span v-if="selectedJob.client_user_role && selectedJob.client_user_role.company_name">({{
                selectedJob.client_user_role.company_name
              }})</span>
          </p>
        </div>

        <!-- Latest status update -->
        <div v-if="selectedJob.status == 1">
         <h3 class="h6 mb-1">Latest Status Update:</h3>
          <p v-if="selectedJob.latest_status_update">
            {{ selectedJob.latest_status_update }} <span>({{ displayTimeAgo(selectedJob.latest_status_update_provided_at) }})</span>
          </p>
          <p v-else>
            -
          </p>
        </div>
        <!-- / Latest status update -->

        <!-- Estimated completion date  -->
        <div v-if="selectedJob.status == 1">
          <h3 class="h6 mb-1">Estimated Completion Date:</h3>
          <estimated-completion-date-field :job="selectedJob"></estimated-completion-date-field>
        </div>
        <!-- / Estimated completion date -->

        <h3 class="h6 mb-1">Description:</h3>
        <p :class="{'mb-0': !isJobClosed(selectedJob)}">
          {{ selectedJob.description }}
        </p>

        <div v-if="isJobClosed(selectedJob)">
          <h3 class="h6 mb-1">This job has been closed. The final bill is:</h3>
          <p>
            {{ formatCostInPence(selectedJob.job_closure.value) }}
          </p>
        </div>

        <!--        <div v-if="isJobClosed(selectedJob)">-->
        <!--          <h3 class="h6 mb-1">Job closure confirmed by client at:</h3>-->
        <!--          <p class="mb-0">-->
        <!--                      <span v-if="selectedJob.job_closure.confirmed_by_client_at">-->
        <!--                        {{ displayTimestamp(selectedJob.job_closure.confirmed_by_client_at) }}-->
        <!--                      </span>-->
        <!--            <span class="text-danger" v-else>Client hasn't confirmed yet</span>-->
        <!--          </p>-->
        <!--        </div>-->
      </div>
      <!-- / Details -->

      <!-- Job Members -->
      <h3 class="h6 mb-2">Job Members</h3>

      <div class="row">
        <div class="col-md-6" v-for="(member, i) in membersToShow">
          <job-member-card
              :member="member"
              :key="i"
              class="mb-3"></job-member-card>
        </div>
      </div>
      <!-- / Job Members -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Jobs from "../../mixins/Jobs";
import JobMemberCard from "./JobDetailsSection/JobMemberCard";
import CloseJobButton from "./MessagingSection/CloseJobButton";
import Display from "../../mixins/Display";
import Dates from "../../mixins/Dates";
import EstimatedCompletionDateField from "./JobDetailsSection/EstimatedCompletionDateField";

export default {
  name: "JobDetailsSection",
  components: {EstimatedCompletionDateField, CloseJobButton, JobMemberCard},
  props: ['selectedJob', 'isMobile'],
  mixins: [Jobs, Display, Dates],
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isProvider: 'isProvider',
      isAdvisor: 'isAdvisor',
      isClient: 'isClient'
    }),

    jobMembership() {
      return _.find(this.selectedJob.job_members, {user_role_id: this.userRole.id});
    },

    isRejected() {
      if (this.jobMembership) {
        return this.isJobRejected(this.selectedJob, this.jobMembership)
      }
      return false;
    },

    isAccepted() {
      if (this.jobMembership) {
        return this.isJobAccepted(this.selectedJob, this.jobMembership)
      }
      return false;
    },

    canAcceptOrReject() {
      return this.canAcceptOrRejectJob(this.selectedJob, this.jobMembership);
    },

    membersToShow() {
      if (this.selectedJob) {
        return _.filter(this.selectedJob.job_members, member => {
          // Hide if disqualified from job
          if (member.disqualified) {
            return false;
          }

          // If provider, hide other providers
          if (this.isProvider && member.user_role) {
            let myUserRoleId = this.userRole.id;
            if (
                member.user_role.role === 'provider'
                && member.user_role.id !== myUserRoleId
            ) {
              return false;
            }
          }

          return true;
        })
      }
      return [];
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.job-details-section {
  flex-grow: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;

  .top {
    background: #fff;
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    flex-shrink: 1;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: 1px solid #efecf3 !important;

    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
    }

    .top-text {
      color: #1f1b2d;
    }
  }

  .inner {
    padding: 10px;
    flex-grow: 1;
    overflow-y: scroll;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.section-header {
  margin-bottom: 30px;
}
</style>