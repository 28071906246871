<template>
  <div class="entry" @click.prevent="handleConversationSelected"
       :class="{'active': isConversationSelected, 'disabled': !conversation.enabled}">
    <div class="entry-flex-wrapper">
      <div class="left">
        <div class="avatars">
          <div class="conversation-member" v-for="member in getMembersToShowForConversation(conversation)">
            <!--            <img width="40" :class="{'opacity-40': !isMemberOnline(member)}"-->
            <!--                 class="rounded-circle" :src="getAvatarSrc(member.user_role)" alt="Avatar"/>-->
            <user-role-avatar width="40" :class="{'opacity-40': !isMemberOnline(member)}"
                              class="rounded-circle" :user-role="member.user_role"></user-role-avatar>
          </div>
        </div>
        <!--        <img width="40" class="rounded-circle"-->
        <!--             src="http://syndesi-api.test/storage/YVeCTUk0JTccMCYZBySenMUGb7qVacr64jlK2aGG.jpg" alt="Avatar"-->
        <!--             data-v-85e7e940="">-->
      </div>
      <!-- Enabled -->
      <div v-if="conversation.enabled" class="right">
        <div class="entry-title" :class="{'unread': isConversationUnread}">
          {{ getConversationName(conversation) }}
        </div>

        <div class="multiple-orgs" v-if="isFromMultipleCompanies">
          Multiple organisations
        </div>

        <div class="entry-footer">
          <div class="left">
            <div class="entry-description" :key="timeAgoRefreshKey">
              <!--          {{ formatDate(conversation.created_at) }}-->
              {{
                displayTimeAgo(conversation.last_human_activity ? conversation.last_human_activity : conversation.created_at)
              }}
            </div>
          </div>
          <div class="right">
            <span v-if="isConversationUnread" class="badge bg-dark">Unread</span>
          </div>
        </div>
      </div>
      <!-- / Enabled -->
      <!-- Disabled -->
      <div v-if="!conversation.enabled" class="right">
        Click to start group chat
      </div>
      <!-- / Disabled -->
    </div>
  </div>
</template>

<script>
import UserRoleAvatar from "../../../components/UserRoleAvatar";

const _ = require('lodash');
import {mapGetters} from 'vuex';
import Images from "../../../mixins/Images";
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
import conversations from "../../../api/conversations";

import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
  name: "ConversationSelectorEntry",
  components: {UserRoleAvatar},
  props: ['conversation', 'selectedConversation'],
  mixins: [Images, Dates, TimeAgoRefresher],
  data() {
    return {
      isEnabling: false
    }
  },
  methods: {
    handleConversationSelected() {
      if (this.conversation.enabled) {
        this.selectConversation();
      } else {
        this.promptToEnableGroupChat();
      }
    },

    promptToEnableGroupChat() {
      let vm = this;
      swal({
        title: "Are you sure you want to start a group chat with " + this.getConversationName(this.conversation) + '?',
        icon: "info",
        buttons: {
          cancel: "No",
          confirm: "Yes"
        }
      }).then((shouldStart) => {
        if (shouldStart) {
          vm.enableGroupChat();
        }
      });
    },

    enableGroupChat() {
      let vm = this;
      vm.isEnabling = true;
      conversations.enableConversation(this.conversation.id).then(r => {
        vm.isEnabling = false;
        toast.success('Group chat enabled successfully');
        vm.selectConversation();
      }).catch(e => {
        console.log(e);
        vm.isEnabling = false;
        toast.error('Error enabling group chat');
      });
    },

    selectConversation() {
      this.$emit('select-conversation');
    },

    getMembersToShowForConversation(conversation) {
      return _.filter(conversation.conversation_members, member => {
        return member.user_role_id !== this.userRole.id;
      })
    },

    getConversationName(conversation) {
      let members = this.getMembersToShowForConversation(conversation);
      if (members.length > 1) {
        let names = _.map(members, member => {
          return member.user_role ? member.user_role.name : 'Unknown';
        });
        if (names.length > 2) {
          let nOthers = names.length - 2;
          return names.slice(0, 2).join(', ') + ' and ' + nOthers + (nOthers == 1 ? ' other' : ' others')
        }
        return names.join(', ');
      } else {
        let firstMember = _.first(members);
        if (firstMember.user_role) {
          return firstMember.user_role.name;
        }
        return 'Unknown';
      }
    },

    capitaliseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getMemberDescriptions(conversation) {
      let descriptions = [];

      let members = this.getMembersToShowForConversation(conversation);
      _.each(members, member => {
        if (member && member.user_role) {
          descriptions.push(
              this.capitaliseFirstLetter(member.user_role.role)
          );
        }
      });

      return descriptions.join(', ');
    },

    isMemberOnline(member) {
      return member.id;
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    isConversationSelected() {
      if (this.conversation && this.selectedConversation) {
        return this.conversation.id === this.selectedConversation.id;
      }
      return false;
    },

    isFromMultipleCompanies() {
      let companyIds = [];
      _.each(this.getMembersToShowForConversation(this.conversation), member => {
        if (member.user_role) {
          companyIds.push(member.user_role.company_id);
        }
      });
      return companyIds.length > 1;
    },

    myConversationMember() {
      return _.find(this.conversation.conversation_members, conversationMember => {
        return conversationMember.user_role_id === this.userRole.id;
      });
    },

    isConversationUnread() {
      let lastHumanActivity = this.conversation.last_human_activity;

      // If no last human activity, then its not unread
      if (!lastHumanActivity) {
        lastHumanActivity = this.conversation.created_at;
      }

      // Let's now get my conversation member
      let myConversationMember = this.myConversationMember;
      let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

      // If I haven't read the conversation, then it's unread if there's been last human activity that isn't the convo being created
      if (!lastReadAt) {
        if (this.conversation.last_human_activity == this.conversation.created_at) {
         return false ;
        }
        return true;
      }

      // If we have both timestamps then let's compare
      if (lastHumanActivity && lastReadAt) {
        let lastHumanActivityMoment = window.moment(lastHumanActivity);
        let lastReadAtMoment = window.moment(lastReadAt);

        return lastHumanActivityMoment.isAfter(lastReadAtMoment);
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.entry {
  word-break: break-word;
  //border: 1px solid #efecf3 !important;
  padding: 15px;
  border-radius: 10px;
  //margin-bottom: 5px;

  &.active, &:hover {
    cursor: pointer;
    background: #efecf3 !important;
  }

  .entry-title {
    font-weight: 500;
    font-size: 15px;

    &.unread {
      font-weight: 600;
    }
  }

  .multiple-orgs {
    margin-top: 5px;
    font-size: 14px;
  }

  .entry-footer {
    margin-top: 5px;
    font-size: 14px;
    display: flex;

    .left {
      flex-grow: 1 !important;
    }

    .right {
      flex-shrink: 1 !important;
      padding-left: 5px !important;
      text-align: right;

      .badge {
        font-size: 11px;
        background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
      }
    }
  }

  .entry-flex-wrapper {
    display: flex;
    align-items: center;

    .left {
      flex-shrink: 1;
    }

    .right {
      flex-grow: 1;
      padding-left: 15px;
    }
  }

  .conversation-member {
    display: inline;

    &:first-child {
      margin-left: 0;
    }

    margin-left: -14px;
  }
}
</style>