<template>
  <div class="mobile-section-selector">
    <!-- Top -->
    <div class="top">
      <div class="left">
        <!-- Back button - mobile -->
        <a class="btn btn-link ps-1 pe-3"
           @click.prevent="$emit('mobile-go-back')">
          <i class="fi-chevron-left fs-sm"></i>
        </a>
        <!-- / Back button - mobile -->
        <b class="top-text">{{ selectedJob.title }}</b>
      </div>
      <div class="right">
        <close-job-button :job="selectedJob"></close-job-button>
      </div>
    </div>
    <!-- / Top -->

    <!-- Inner -->
    <div class="inner">

      <!-- Conversations -->
      <div class="option" @click.prevent="() => $emit('mobile-select-section', 'messaging')">
        <div class="left">
          <i class="fi fi-chat-circle"></i>
          Conversations
        </div>
        <div class="right">
          <span v-if="doesJobHaveUnreadConversations" class="badge bg-dark">Unread</span>
        </div>
      </div>
      <!-- / Conversations -->

      <!-- Quotes -->
      <div class="option" @click.prevent="() => $emit('mobile-select-section', 'quotes')">
        <div class="left">
          <i class="fi fi-briefcase"></i>
          Quotes
        </div>
        <div class="right">
          <span v-if="shouldShowQuoteNow" class="badge bg-dark">Quote Now!</span>
          <span v-if="shouldShowReviewQuotes" class="badge bg-dark">Review Quotes</span>
        </div>
      </div>
      <!-- / Quotes -->

      <!-- Job Details -->
      <div class="option" @click.prevent="() => $emit('mobile-select-section', 'job-details')">
        <div class="left">
          <i class="fi fi-info-circle"></i>
          Job Details
        </div>
      </div>
      <!-- / Job Details -->

    </div>
    <!-- / Inner -->
  </div>
</template>

<script>
import CloseJobButton from "./MessagingSection/CloseJobButton";
import {mapGetters} from "vuex";

export default {
  name: "MobileSectionSelector",
  props: ['selectedJob'],
  components: {CloseJobButton},
  computed: {
    ...mapGetters('conversations', {
      conversations: 'conversations'
    }),

    ...mapGetters('auth', {
      userRole: 'userRole',
      isProvider: 'isProvider',
      isClient: 'isClient'
    }),

    doesJobHaveUnreadConversations() {
      let vm = this;
      if (this.selectedJob) {
        let hasUnreadMessages = false;
        let conversationsForJob = _.filter(this.conversations, {job_id: this.selectedJob.id});
        _.each(conversationsForJob, conversation => {

          let lastHumanActivity = conversation.last_human_activity;
          // If no last human activity, let's default it to the conversation created at date
          if (!lastHumanActivity) {
            lastHumanActivity = conversation.created_at;
          }

          // Let's now get my conversation member
          let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
            return conversationMember.user_role_id === vm.userRole.id;
          });
          let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

          // If I haven't read the conversation, then it's unread
          if (!lastReadAt) {
            hasUnreadMessages = true;
          } else {
            // If we have both timestamps then let's compare
            let lastHumanActivityMoment = window.moment(lastHumanActivity);
            let lastReadAtMoment = window.moment(lastReadAt);

            let isUnreadBecauseOfTimestamps = lastHumanActivityMoment.isAfter(lastReadAtMoment);
            if (isUnreadBecauseOfTimestamps) {
              hasUnreadMessages = true;
            }
          }
        });
        return hasUnreadMessages;
      }
      return false;
    },

    unhiddenJobQuotes() {
      if (this.job && this.job.job_quotes) {
        return _.filter(this.selectedJob.job_quotes, jq => {
          return !jq.hidden;
        });
      }
      return [];
    },

    shouldShowQuoteNow() {
      if (
          this.isProvider
          && this.selectedJob
          && this.selectedJob.status === 0
          && this.unhiddenJobQuotes
          && this.unhiddenJobQuotes.length === 0
      ) {
        return true;
      }
      return false;
    },

    shouldShowReviewQuotes() {
      if (
          this.isClient
          && this.selectedJob
          && this.selectedJob.status === 0
          && this.unhiddenJobQuotes
          && this.unhiddenJobQuotes.length > 0
      ) {
        return true;
      }
      return false;
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-section-selector {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex-grow: 1;

  .top {
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    border-bottom: 1px solid #efecf3 !important;
    padding-left: 14px;
    display: flex;
    align-items: center;

    .top-text {
      color: #1f1b2d;
    }

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      padding-right: 14px;
    }
  }

  .inner {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 10px;
    width: 100%;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .option {
      margin-bottom: 15px;
      padding: 20px 30px;
      font-weight: 600;
      //text-align: center;
      border-radius: 10px;
      background: #fff;
      width: 100%;
      cursor: pointer;

      .fi {
        margin-right: 15px;
        // color: #fd5631;
        color: rgb(var(--primary-colour));
      }

      &:hover {
        color: rgb(var(--primary-colour));
      }

      display: flex;

      .left {
        flex-grow: 1;
      }

      .right {
        flex-shrink: 1;
        text-align: right;

        .badge {
          font-size: 11px;
          background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
        }
      }
    }
  }
}
</style>