<template>
  <div class="update-job-status-button-and-modal-wrapper">
    <button class="btn btn-icon btn-outline-primary me-2" type="button"
            @click.prevent="openModal"
    >
      <i class="fi-alert-circle"></i>
    </button>

    <vue-final-modal v-model="showModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <h5 class="modal-title">
              Provide Status Update
            </h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">

            <loading-screen :is-loading="isSaving"></loading-screen>

            <!-- Loading -->
            <div class="loader" v-if="isLoadingStatusUpdateOptions">
              <spinner></spinner>
            </div>
            <!-- / Loading -->

            <!-- Loaded -->
            <div class="loaded" v-if="!isLoadingStatusUpdateOptions">

              <!-- Stage 0 -->
              <div class="stage-0" v-if="stage === 0">
                <!-- Shortcuts -->
                <div>
                  <div>
                    <div class="shortcut btn btn-primary"
                         @click.prevent="selectOption(shortcut.description)"
                         v-for="shortcut in statusUpdateOptions" :key="shortcut.id">
                      {{ shortcut.description }}
                    </div>
                  </div>
                </div>
                <!-- / Shortcuts -->

                <!-- Provide own update -->
                <a href="#"
                   @click.prevent="selectOption()"
                   class="btn btn-link text-primary pe-0 ps-0 own-update">Click here to provide a custom
                  update</a>
                <!-- / Provide own update -->
              </div>
              <!-- / Stage 0 -->

              <!-- Stage 1 -->
              <div v-if="stage === 1" class="stage-1">

                <a href="#" class="btn btn-link text-primary ps-0 pe-0 mb-4 pt-0 pb-0" @click.prevent="goBack">
                  <i class="fi-chevron-left fs-sm me-2"></i>
                  Back
                </a>

                <!-- Description Entry -->
                <div class="description-entry">

                  <label class="form-label" for="description">How is the project going?</label>

                  <input v-model="form.description" id="description" placeholder="The project is going well..."
                         type="textarea"
                         class="form-control">

                </div>
                <!-- / Description Entry -->

                <!-- Save -->
                <div class="actions">
                  <a href="#" @click.prevent="attemptSave" class="btn btn-primary" :class="{'disabled': !this.form.description.trim().length}">Save Update</a>
                </div>
                <!-- / Save -->

              </div>
              <!-- / Stage 1 -->

            </div>
            <!-- / Loaded -->

          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import statusUpdates from "../../../../api/status-updates";
import Spinner from "../../../../components/Spinner";
import LoadingScreen from "../../../../components/LoadingScreen";
import Forms from "../../../../mixins/Forms";
import {useToast} from 'vue-toastification';

const toast = useToast();

export default {
  name: "UpdateJobStatusButtonAndModal",
  components: {LoadingScreen, Spinner},
  props: ['job'],
  mixins: [Forms],
  data() {
    return {
      showModal: false,
      statusUpdateOptions: [],
      isLoadingStatusUpdateOptions: false,
      stage: 0,
      form: {
        tone: 0,
        description: ''
      },
      serverErrors: [],
      isSaving: false
    }
  },
  created() {
    this.loadStatusUpdateOptions();
  },
  methods: {
    loadStatusUpdateOptions() {
      let vm = this;
      vm.statusUpdateOptions = [];
      vm.isLoadingStatusUpdateOptions = true;
      statusUpdates.getStatusUpdateOptions().then(r => {
        vm.isLoadingStatusUpdateOptions = false;
        vm.statusUpdateOptions = r.data;
      }).catch(e => {
        vm.isLoadingStatusUpdateOptions = false;
        console.log(e);
      });
    },

    openModal() {
      this.stage = 0;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    selectOption(description = '') {
      this.form.description = description;
      this.stage = 1;
    },

    goBack() {
      this.stage = 0;
    },

    getValidationErrors() {
      let errors = [];

      if (this.form.tone == null) {
        errors.push('Please select an update tone');
      }

      if (this.form.description == null || this.form.description.trim().length == 0) {
        errors.push('Please provide a comment');
      }

      return errors;
    },

    attemptSave() {
      let vm = this;

      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        toast.error(validationErrors[0]);
        return false;
      }

      vm.save();
    },

    save() {
      let vm = this;
      vm.isSaving = true;
      vm.serverErrors = [];
      statusUpdates.createAdHocUpdate(this.job.id, this.form.tone, this.form.description).then(r => {
        vm.isSaving = false;
        toast.success('Status update created successfully');
        vm.closeModal();
      }).catch(e => {
        vm.isSaving = false;
        console.log(e);
        vm.setAndNotifyErrors(e);
      });
    }
  }
}
</script>

<style lang="scss">
.update-job-status-button-and-modal-wrapper {
  display: inline;

  .loader {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .loaded {
    text-align: center;
  }

  .shortcut {
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
    padding: 10px 17px;
    font-size: 14px;
    border-radius: 40px;
    font-weight: 600;
    display: inline-block;
  }

  .own-update {
    font-size: 15px;
  }

  .stage-0 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .stage-1 {
    text-align: left;

    padding-bottom: 10px;

    .actions {
      margin-top: 30px;
    }
  }
}
</style>