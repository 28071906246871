<template>
  <div class="job-sidebar" :class="{'is-mobile': isMobile}">
    <div class="top">
      <!-- Search -->
      <div class="search-bar">
        <div class="left">
          <i @click.prevent="focusSearchBox" class="fi fi-search"></i>
        </div>
        <div class="right">
          <input type="text"
                 ref="searchBox"
                 id="job-selector-search"
                 placeholder="Search jobs or people..."
                 class="form-control" v-model="searchQuery"/>
        </div>
      </div>
      <!-- / Search -->
    </div>

    <!-- Tabs -->
    <status-tabs v-if="!searchQuery.trim()" :status="statusFilter" @set-status="setStatus"></status-tabs>
    <!-- / Tabs -->

    <!-- Number of results -->
    <div v-if="!isLoading && searchQuery.trim() && jobResults" class="number-of-results">
      <div class="left">
        Showing {{ jobResults.length }} {{ jobResults.length == 1 ? 'result' : 'results' }} for "{{ searchQuery }}"
      </div>
      <div class="right">
        <i class="fi fi-x" @click.prevent="clearSearch"></i>
      </div>
    </div>
    <!-- / Number of results -->

    <div class="inner" ref="inner">
      <!-- Loader -->
      <div class="loader" v-if="isLoading">
        <spinner></spinner>
      </div>
      <!-- / Loader -->

      <!-- Jobs List -->
      <div class="jobs-list" v-if="!isLoading">
        <div v-for="job in jobResultsSortedByLastActivity"
             :ref="getJobRef(job)"
             class="job-result" :key="job.id">
          <!-- Entry -->
<!--          <div class="entry"-->
<!--               @click.prevent="() => selectJob(job)"-->
<!--               :class="{'active': isJobSelected(job)}">-->
<!--            <div class="entry-title">{{ job.title }}</div>-->
<!--            <div class="entry-description">{{ getStatusLabel(job.status) }}</div>-->
<!--            <div class="entry-timestamp" :key="timeAgoRefreshKey">{{-->
<!--                displayTimeAgo(job.last_activity_for_my_user_role)-->
<!--              }}-->
<!--            </div>-->
<!--            &lt;!&ndash; Submenu &ndash;&gt;-->
<!--            <div class="icon-submenu" :key="submenuKey"-->
<!--                 v-if="isMembershipAcceptedForSelectedJob && isJobSelected(job) && !isMobile">-->
<!--              <div-->
<!--                  class="icon-submenu-option"-->
<!--                  v-tooltip="option.label"-->
<!--                  @click.prevent="selectSection(option.value)"-->
<!--                  :class="{'selected': isSectionSelected(option.value), 'marked': option.marked}"-->
<!--                  v-for="(option, i) in submenuOptions"-->
<!--                  :key="getSubmenuOptionKey(i)">-->
<!--                <i :class="option.icon"></i>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; / Submenu &ndash;&gt;-->
<!--          </div>-->
          <!-- / Entry -->

          <job-selector-entry :job="job"
                              @job-selected="() => selectJob(job)"
                              :active="isJobSelected(job)"
                              :is-membership-accepted-for-selected-job="isMembershipAcceptedForSelectedJob"
                              :is-mobile="isMobile"
                              :selected-section="selectedSection"
                              @select-section="val => selectSection(val)"
          ></job-selector-entry>

          <!-- Submenu -->
          <!--        <div class="submenu" v-if="isJobSelected(job)">-->
          <!--          <div-->
          <!--              class="submenu-option"-->
          <!--              @click.prevent="selectSection(option.value)"-->
          <!--              :class="{'selected': isSectionSelected(option.value)}"-->
          <!--              v-for="(option, i) in submenuOptions"-->
          <!--              :key="getSubmenuOptionKey(i)">-->
          <!--            <div class="left">-->
          <!--              &bull;-->
          <!--            </div>-->
          <!--            <div class="right">-->
          <!--              {{ option.label }}-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <!-- / Submenu -->
        </div>
      </div>
      <!-- / Jobs List -->
    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner";
import {useToast} from 'vue-toastification';
import Dates from "../../mixins/Dates";
import Jobs from "../../mixins/Jobs";
import {mapGetters} from 'vuex';
import StatusTabs from "./JobSelector/StatusTabs";
import TimeAgoRefresher from "../../mixins/TimeAgoRefresher";
import JobSelectorEntry from "./JobSelector/JobSelectorEntry";

const _ = require('lodash');
const toast = useToast();

export default {
  name: "JobSelector",
  props: ['selectedJob', 'selectedSection', 'initialJobId', 'initialJobTitle', 'isMobile', 'isMembershipAcceptedForSelectedJob'],
  components: {JobSelectorEntry, StatusTabs, Spinner},
  created() {
    // if (this.initialJobTitle) {
    //   this.searchQuery = this.initialJobTitle;
    //   window.setTimeout(() => {
    //     this.selectFirstJob();
    //   }, 300);
    // }

    this.waitUntilLoadedAndThenDetermineWhatJobToInitiallySelect();
  },
  mixins: [Jobs, Dates, TimeAgoRefresher],
  data() {
    return {
      // jobs: [],
      searchQuery: '',
      statusFilter: 0,
      submenuKey: 0,
      dontChangeJobOnFilterChange: false,
      jobSelectedById: null
      // isLoading: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isClient: 'isClient',
      isProvider: 'isProvider',
      isAdvisor: 'isAdvisor'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs',
      isLoading: 'isLoading'
    }),

    ...mapGetters('conversations', {
      conversations: 'conversations'
    }),

    jobResults() {
      if (this.jobs && this.jobs.length) {
        // let jobs = _.filter(this.jobs, job => {
        //   let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
        //   return jobMembership.status == 1; // Accepted jobs only
        // });

        let jobs = _.filter(this.jobs, job => {
          let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
          // return jobMembership.status == 1; // Accepted jobs only

          // If job is quoting, show pending && accepted jobs
          if (job.status == 0) {
            return jobMembership.status == 0 || jobMembership.status == 1;
          }

          // Otherwise show only accepted jobs
          return jobMembership.status == 1; // Accepted jobs only
        });

        if (this.searchQuery.trim()) {
          return this.searchJobs(jobs, this.searchQuery);
        } else {
          return _.filter(jobs, job => {
            return job.status === this.statusFilter;
          });
        }

      }
      return [];
    },

    jobResultsSortedByLastActivity() {
      let withLastActivityTimestamps = _.map(this.jobResults, job => {
        let jobLocal = {...job};
        let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});
        if (jobMembership) {
          jobLocal.last_activity_for_my_user_role = (jobMembership.last_activity ? jobMembership.last_activity : jobMembership.created_at);
        } else {
          jobLocal.last_activity_for_my_user_role = job.created_at;
        }
        return jobLocal;
      });

      // Only sort by last activity if no search query
      if (this.searchQuery.trim()) {
        return withLastActivityTimestamps;
      }
      return _.sortBy(withLastActivityTimestamps, 'last_activity_for_my_user_role').reverse();
    },

    shouldQuotesIconBeMarked() {
      if (this.isClient) {
        return this.doesSelectedJobHavePendingQuotes;
      }
      if (this.isProvider) {
        return this.haveINotQuotedForSelectedJobYet;
      }
      return false;
    },

    doesSelectedJobHaveUnreadConversations() {
      let vm = this;
      if (this.selectedJob) {
        let hasUnreadMessages = false;

        // let conversationsForJob = _.filter(this.conversations, {job_id: this.selectedJob.id});

        let conversationsForJob = _.filter(this.conversations, conversation => {
          return (
              conversation.job_id == this.selectedJob.id
              && conversation.enabled == true
          );
        });

        _.each(conversationsForJob, conversation => {

          let lastHumanActivity = conversation.last_human_activity;
          // If no last human activity, let's default it to the conversation created at date
          if (!lastHumanActivity) {
            lastHumanActivity = conversation.created_at;
          }

          // Let's now get my conversation member
          let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
            return conversationMember.user_role_id === vm.userRole.id;
          });
          let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

          // If I haven't read the conversation, then it's unread if there is last human activity
          if (!lastReadAt) {
            if (conversation.last_human_activity) {
              hasUnreadMessages = true;
            }
          } else {
            // If we have both timestamps then let's compare
            let lastHumanActivityMoment = window.moment(lastHumanActivity);
            let lastReadAtMoment = window.moment(lastReadAt);

            let isUnreadBecauseOfTimestamps = lastHumanActivityMoment.isAfter(lastReadAtMoment);
            if (isUnreadBecauseOfTimestamps) {
              hasUnreadMessages = true;
            }
          }
        });
        return hasUnreadMessages;
      }
      return false;
    },

    doesSelectedJobHavePendingQuotes() {
      if (this.selectedJob && this.selectedJob.status === 0 && this.selectedJob.job_quotes) {
        return this.selectedJob.job_quotes.length > 0;
      }
      return false;
    },

    haveINotQuotedForSelectedJobYet() {
      if (this.selectedJob && this.selectedJob.status === 0 && this.selectedJob.job_quotes) {
        return this.selectedJob.job_quotes.length === 0;
      }
      return false;
    },

    submenuOptions() {
      return [
        {
          label: 'Messaging',
          value: 'messaging',
          icon: 'fi-chat-circle',
          marked: this.doesSelectedJobHaveUnreadConversations
        },
        {
          label: 'Quotes',
          value: 'quotes',
          icon: 'fi-briefcase',
          marked: this.shouldQuotesIconBeMarked
        },
        {
          label: 'Job Details',
          value: 'job-details',
          icon: 'fi-info-circle'
        }
      ];
    }
  },
  watch: {
    searchQuery(newResults, oldResults) {
      // if (newResults.length !== oldResults.length) {
      // window.setTimeout(() => {
      //   this.selectFirstJobIfNotOnMobile();
      // }, 300);
      // }
      this.selectFirstJobIfNotOnMobile();
    },
    statusFilter() {
      if (this.dontChangeJobOnFilterChange) {
        this.dontChangeJobOnFilterChange = false;
      } else {
        this.selectFirstJobIfNotOnMobile();
      }
    },
    'conversations': {
      handler() {
        this.submenuKey += 1;
      },
      deep: true
    },
    selectedJob(newSelectedJob, oldSelectedJob) {
      this.submenuKey += 1;
      if (newSelectedJob && oldSelectedJob && newSelectedJob.id === oldSelectedJob.id) {
        if (newSelectedJob.status !== this.statusFilter) {
          this.dontChangeJobOnFilterChange = true;
          this.statusFilter = newSelectedJob.status;
        }
      }
    }
  },
  methods: {
    getJobRef(job) {
      return 'job-' + job.id;
    },

    determineSearchParams() {
      return {
        q: this.searchQuery,
        results_per_page: 1000,
        include_my_quotes: true
      }
    },

    waitUntilLoadedAndThenDetermineWhatJobToInitiallySelect() {
      if (this.isLoading) {
        window.setTimeout(() => {
          this.waitUntilLoadedAndThenDetermineWhatJobToInitiallySelect();
        }, 100);
      } else {
        this.determineWhatJobToInitiallySelect();
      }
    },

    determineWhatJobToInitiallySelect() {
      if (this.initialJobId) {
        window.setTimeout(() => {
          let initialJob = _.find(this.jobs, job => {
            let jobMembership = _.find(job.job_members, {user_role_id: this.userRole.id});

            // If job is quoting, show pending && accepted jobs
            if (job.status == 0) {
              let jobMembershipCondition = (jobMembership.status == 0 || jobMembership.status == 1);
            } else {
              // Otherwise show only accepted jobs
              let jobMembershipCondition = (jobMembership.status == 1); // Accepted jobs only
            }

            return (jobMembership && job.id == this.initialJobId);
          });

          if (initialJob) {
            this.dontChangeJobOnFilterChange = true;
            this.statusFilter = initialJob.status;
            this.selectJob(initialJob);
            this.jobSelectedById = true;
            this.$nextTick(() => {
              this.scrollJobSelectorToSelectedJob();
            });
          } else {
            this.selectFirstJobIfNotOnMobile(true);
          }
        }, 100);
      } else {
        window.setTimeout(() => {
          this.selectFirstJobIfNotOnMobile(true);
        }, 100);
      }
    },

    setStatus(e) {
      this.statusFilter = e;
    },

    focusSearchBox() {
      this.$refs['searchBox'].focus();
    },

    clearSearch() {
      this.searchQuery = '';
    },

    selectFirstJob() {
      let vm = this;
      let firstJob = _.first(this.jobResultsSortedByLastActivity);
      if (firstJob) {
        vm.selectJob(firstJob);
      } else {
        vm.selectJob(null);
      }
    },

    selectFirstJobIfNotOnMobile(shouldCheckNextSection = false) {
      // Only autoselect job if on mobile
      let vm = this;
      if (!this.isMobile) {
        let firstJob = _.first(this.jobResultsSortedByLastActivity);
        if (firstJob) {
          vm.selectJob(firstJob);
        } else {
          vm.selectJob(null);
          if (shouldCheckNextSection && this.statusFilter < 2) {
            vm.statusFilter = this.statusFilter + 1;
            vm.selectFirstJobIfNotOnMobile(true);
          }
        }
      }
    },

    selectJob(job) {
      if (!job) {
        this.$emit('select-job', null);
        return false;
      }
      if (this.selectedJob && job.id == this.selectedJob.id && !this.isMobile) {
        return false;
      }
      this.$emit('select-job', job);

      if (
          this.$route.query.section
          && !window.alreadyPreselectedMessagingSection
      ) {
        let allowedSections = _.map(this.submenuOptions, 'value');
        if (allowedSections.includes(this.$route.query.section)) {
          this.selectSection(this.$route.query.section);
          window.alreadyPreselectedMessagingSection = true;
        }
      } else {
        this.selectSection(
            _.first(this.submenuOptions).value
        );
      }
    },

    isJobSelected(job) {
      if (job && this.selectedJob) {
        return job.id === this.selectedJob.id;
      }
      return false;
    },

    getSubmenuOptionKey(i) {
      return 'submenu-option-' + i;
    },

    selectSection(section) {
      this.$emit('select-section', section);
    },

    isSectionSelected(section) {
      if (section && this.selectedSection) {
        return section === this.selectedSection;
      }
      return false;
    },

    doesJobHaveUnreadConversations() {
      let hasUnreadMessages = true;
      let conversationsForJob = _.find(this.conversations, {job_id: this.selectedJob.id});
      _.each(conversationsForJob, conversation => {

        let lastHumanActivity = conversation.last_human_activity;
        // If no last human activity, let's default it to the conversation created at date
        if (!lastHumanActivity) {
          lastHumanActivity = this.conversation.created_at;
        }

        // Let's now get my conversation member
        let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
          return conversationMember.user_role_id === this.userRole.id;
        });
        let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

        // If I haven't read the conversation, then it's unread
        if (!lastReadAt) {
          hasUnreadMessages = true;
        } else {
          // If we have both timestamps then let's compare
          let lastHumanActivityMoment = window.moment(lastHumanActivity);
          let lastReadAtMoment = window.moment(lastReadAt);

          hasUnreadMessages = lastHumanActivityMoment.isAfter(lastReadAtMoment);
        }
      });
      return hasUnreadMessages;
    },

    scrollJobSelectorToSelectedJob() {
      if (!this.selectedJob) {
        return false;
      }
      this.$nextTick(() => {
        this.$refs['job-' + this.selectedJob.id].scrollIntoView({block: 'start', scrollBehavior: 'smooth'});
      });
    }
  }
}
</script>

<style scoped lang="scss">
.search-bar {
  width: 100%;
  //margin-top: 5px;
  //margin-bottom: 15px;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-left: 4px;

    i {
      font-size: 14px;
    }
  }

  .right {
    flex-grow: 1;
  }

  input {
    background: none;
    color: #fff;
    border: none;
    padding-left: 8px;
    padding-right: 8px;

    &:focus, &:active {
      border: 0;
      box-shadow: none;
    }

    //border-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
}

.loader {
  text-align: center;
  margin-top: 20px;
}

.job-sidebar {
  display: flex;
  flex-direction: column;

  .top {
    padding-left: 10px;
    padding-right: 10px;
    height: 55px;
    min-height: 55px;
    max-height: 55px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }

  .status-tabs {
    margin-bottom: 3px;
  }

  .number-of-results {
    //margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    max-width: 100%;
    width: 100%;
    word-break: break-word;

    display: flex;

    .left {
      flex-grow: 1;
      padding-left: 4px;
      color: rgba(255, 255, 255, 0.8);
    }

    .right {
      padding-left: 8px;
      padding-right: 5px;
      display: flex;
      align-items: center;

      i {
        font-size: 12px;
        color: #fff;
        cursor: pointer;
      }

      flex-shrink: 1;
    }
  }

  .inner {
    flex-grow: 1;
    padding: 10px;
    overflow-y: scroll;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  flex-shrink: 1;
  //background: #fff;
  background: #2C283A;
  //background: #171325;
  border-right: 1px solid #efecf3 !important;

  &.is-mobile {
    border-right: none !important;
  }

  .category {
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 5px;
    font-weight: bold;
    //text-align: center;
  }

  .icon-submenu {
    margin-top: 8px;
    //margin-bottom: 5px;
    //padding-left: 15px;
    text-align: right;

    .icon-submenu-option {
      display: inline;
      margin-left: 15px;
      position: relative;

      //color: #fd5631;

      &.selected, &:hover {
        //color: #fff;
        // color: #fd5631;
        color:  rgb(var(--primary-colour));
        cursor: pointer;
      }

      &.marked {
        &::after {
          content: "";
          // background: #fd5631;
          background:  rgb(var(--primary-colour));
          width: 5px;
          height: 5px;
          display: inline-block;
          position: absolute;
          top: 2px;
          right: -5px;
          border-radius: 50%;
        }
      }
    }
  }

  .submenu {
    margin-top: 6px;
    margin-bottom: 5px;

    .submenu-option {
      padding-left: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 15px;
      display: flex;
      cursor: pointer;

      &.selected {
        // color: #fd5631;
        color:  rgb(var(--primary-colour));
        //font-weight: bold;
      }

      .left {
        flex-shrink: 1;
        min-width: 15px;
      }

      .right {
        flex-grow: 1;
      }
    }
  }

  .entry {
    //border: 1px solid #efecf3 !important;
    padding: 13px 15px;
    border-radius: 10px;
    //margin-bottom: 5px;
    //color: #fff;
    color: rgba(255, 255, 255, 0.8);

    &.active, &:hover {
      //background: #efecf3 !important;
      background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
      color: #fff;
      cursor: pointer;
    }

    //
    //&:hover {
    //  cursor: pointer;
    //  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    //}

    .entry-title {
      font-weight: 600;
      font-size: 15px;
      word-break: break-word;
    }

    .entry-description, .entry-timestamp {
      margin-top: 5px;
      font-size: 14px;
    }

    .entry-flex-wrapper {
      display: flex;
      align-items: center;

      .left {
        flex-shrink: 1;
      }

      .right {
        flex-grow: 1;
        padding-left: 15px;
      }
    }
  }
}
</style>