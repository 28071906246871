<template>
  <div class="messaging" :class="{'is-mobile': isMobile}">
    <div class="messaging-wrapper">
      <div class="flex-wrapper">
        <job-selector :selected-job="selectedJob"
                      v-show="shouldShowLayer(0)"
                      @select-job="selectJob"
                      :is-mobile="isMobile"
                      :selected-section="selectedSection"
                      :initial-job-title="initialJobTitle"
                      :initial-job-id="initialJobId"
                      :is-membership-accepted-for-selected-job="isMembershipAcceptedForSelectedJob"
                      @select-section="selectSection"></job-selector>

        <!-- Section Selector (Mobile Only) -->
        <mobile-section-selector
            v-if="isMembershipAcceptedForSelectedJob && isMobile && shouldShowLayer(1)"
            @mobile-go-back="mobileGoBack"
            :selected-job="selectedJob"
            @mobile-select-section="mobileSelectSection"></mobile-section-selector>
        <!-- / Section Selector (Mobile Only -->

        <!-- Conversations -->
        <conversation-selector
            v-if="isMembershipAcceptedForSelectedJob && selectedSection === 'messaging' && shouldShowLayer(2)"
            :is-loading="isLoadingConversations"
            :is-mobile="isMobile"
            :selected-job="selectedJob"
            @mobile-go-back="mobileGoBack"
            :conversations="conversations"
            :selected-conversation="selectedConversation"
            @select-conversation="selectConversation"></conversation-selector>

        <messaging-section v-if="isMembershipAcceptedForSelectedJob && selectedSection === 'messaging'
&& selectedJob && selectedConversation && shouldShowLayer(3)"
                           :is-mobile="isMobile"
                           @mobile-go-back="mobileGoBack"
                           @navigate-to-quotes="navigateToQuotes"
                           @navigate-to-job-details="navigateToJobDetails"
                           :selected-job="selectedJob"
                           :selected-conversation="selectedConversation">
        </messaging-section>
        <!-- / Conversations -->

        <!-- Quotes -->
        <quotes-section
            v-if="isMembershipAcceptedForSelectedJob && selectedSection === 'quotes' && selectedJob && shouldShowLayer(2)"
            :selected-job="selectedJob"
            :is-mobile="isMobile"
            @discuss-quote="discussQuote"
            @chat-to-client="chatToClient"
            @mobile-go-back="mobileGoBack"
            @go-to-messaging="goToMessaging"
            @quote-accepted="handleQuoteAccepted"
        >
        </quotes-section>
        <!-- / Quotes -->

        <!-- Job Details -->
        <job-details-section
            v-if="isMembershipAcceptedForSelectedJob && selectedSection === 'job-details' && selectedJob && shouldShowLayer(2)"
            :is-mobile="isMobile"
            @mobile-go-back="mobileGoBack"
            :selected-job="selectedJob">
        </job-details-section>
        <!-- / Job Details -->

        <!-- Invited To Quote -->
        <invited-to-quote v-if="!isMembershipAcceptedForSelectedJob && selectedJob"
                          :selected-job="selectedJob"
                          :is-mobile="isMobile"
                          @mobile-go-back="() => selectJob(null)"></invited-to-quote>
        <!-- / Invited To Quote -->
      </div>
    </div>
  </div>
</template>

<script>
import JobSelector from "./Messaging/JobSelector";
import ConversationSelector from "./Messaging/ConversationSelector";
// import conversations from "../api/conversations";
import Forms from "../mixins/Forms";
import MessagingSection from "./Messaging/MessagingSection";
import QuotesSection from "./Messaging/QuotesSection";
import JobDetailsSection from "./Messaging/JobDetailsSection";
import eventBus from "../eventBus";
import MobileSectionSelector from "./Messaging/MobileSectionSelector";
import {mapGetters, mapActions} from "vuex";
import InvitedToQuote from "./Messaging/InvitedToQuote";

import {useToast} from "vue-toastification";
const toast = useToast();

export default {
  name: "Messaging",
  mixins: [Forms],
  components: {
    InvitedToQuote,
    MobileSectionSelector,
    QuotesSection, MessagingSection, ConversationSelector, JobSelector, JobDetailsSection
  },
  data() {
    return {
      serverErrors: [],

      selectedJobId: null,
      selectedSection: 'messaging',

      // isLoadingConversations: false,
      // isLoadingConversationsSilently: false,
      // conversations: [],
      selectedConversation: null,

      initialJobTitle: '',
      initialJobId: null,
      windowWidth: window.innerWidth,

      viewLayer: 0, // 0, 1, 2
    }
  },
  created() {
    window.alreadyPreselectedMessagingSection = false;

    if (this.$route.query.initialJobTitle) {
      this.initialJobTitle = this.$route.query.initialJobTitle;
    } else {
      this.initialJobTitle = '';
    }

    if (this.$route.query.initialJobId) {
      this.initialJobId = this.$route.query.initialJobId;
    } else {
      this.initialJobId = null;
    }

    let vm = this;
    // eventBus.$on('user-added-to-conversation', () => {
    //   vm.reloadConversations();
    // });

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    eventBus.$on('messaging-back-to-index', () => {
      this.selectJob(null);
    });

    eventBus.$on('message-received', e => {
      if (!this.userRole || e.idOfUserRoleReceivingMessage !== this.userRole.id) {
        return false;
      }
      // don't alert if it's me sending
      if (e.idOfUserRoleWhoSentMessage == this.userRole.id) {
        return false;
      }
      if (!this.selectedConversation || (this.selectedConversation && e.conversationId !== this.selectedConversation.id)) {
        toast.info("New message from " + e.nameOfUserRoleWhoSentMessage, {
          onClick: (closeToast) => {
            let job = _.find(this.jobs, {id: e.jobId});
            if (job) {
              vm.selectJob(job);
              vm.$nextTick(() => {
                vm.selectedSection = 'messaging';
                let conversation = _.find(vm.conversations, {id: e.conversationId});
                if (conversation) {
                  vm.selectConversation(conversation);
                }
              });
            }
            closeToast();
          }
        });
      }
    });
  },
  beforeDestroy() {
    window.clearInterval(this.timeAgoRefresher);
    this.timeAgoRefresher = null;
  },
  watch: {
    conversationIds(n, o) {
      if (JSON.stringify(n) !== JSON.stringify(o)) {
        this.selectFirstConversationIfNotMobile();
      }
    },

    selectedSection() {
      this.clearConfirmingAcceptanceForQuoteId();
    }
  },
  computed: {
    ...mapGetters('conversations', {
      allConversations: 'conversations',
      isLoadingConversations: 'isLoading'
    }),

    ...mapGetters('jobs', {
      jobs: 'jobs'
    }),


    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    selectedJob() {
      if (this.selectedJobId) {
        return _.find(this.jobs, {id: this.selectedJobId});
      }
      return null;
    },

    isMembershipAcceptedForSelectedJob() {
      if (this.selectedJob) {
        let jobMembership = _.find(this.selectedJob.job_members, {user_role_id: this.userRole.id});
        return jobMembership.status == 1; // is accepted
      }
      return true;
    },

    isMobile() {
      return this.windowWidth < 1000;
    },

    conversations() {
      if (this.selectedJob) {
        return _.filter(this.allConversations, conversation => {
          return conversation.job_id == this.selectedJobId;
        });
      }
      return [];
    },

    conversationIds() {
      return _.map(this.conversations, 'id');
    }
  },
  methods: {
    ...mapActions('jobs', {
      clearConfirmingAcceptanceForQuoteId: 'clearConfirmingAcceptanceForQuoteId'
    }),

    shouldShowLayer(layer) {
      return (!this.isMobile || this.viewLayer === layer);
    },

    selectJob(job) {
      this.selectedJobId = job ? job.id : null;
      if (this.selectedJobId) {
        // this.loadConversations();
        this.viewLayer = 1;
      } else {
        this.conversations = [];
        this.selectedConversation = null;
        this.viewLayer = 0;
      }
    },

    selectSection(section) {
      this.selectedSection = section;
    },

    mobileSelectSection(section) {
      this.selectedSection = section;
      this.viewLayer = 2;
    },

    navigateToQuotes() {
      this.selectedSection = 'quotes';
      this.viewLayer = 2;
    },

    navigateToJobDetails() {
      this.selectedSection = 'job-details';
      this.viewLayer = 2;
    },

    discussQuote(quote) {
      this.selectedSection = 'messaging';

      let conversationsWithProvider = _.filter(this.conversations, conversation => {
        let userRoleIds = _.map(conversation.conversation_members, 'user_role_id');
        return userRoleIds.includes(quote.provider_user_role_id);
      });
      let withConversationLength = _.map(conversationsWithProvider, c => {
        return {
          ...c,
          number_of_conversation_members: c.conversation_members.length
        }
      });
      let sortedByNumberOfMembers = _.sortBy(withConversationLength, 'number_of_conversation_members');
      let conversationToSelect = sortedByNumberOfMembers.length ? _.first(sortedByNumberOfMembers) : null;

      this.selectConversation(conversationToSelect);
    },

    goToMessaging() {
      this.selectedSection = 'messaging';
    },

    chatToClient() {
      this.selectedSection = 'messaging';

      let conversationsWithClient = _.filter(this.conversations, conversation => {
        let userRoleIds = _.map(conversation.conversation_members, 'user_role_id');
        return userRoleIds.includes(this.selectedJob.client_user_role_id);
      });
      let withConversationLength = _.map(conversationsWithClient, c => {
        return {
          ...c,
          number_of_conversation_members: c.conversation_members.length
        }
      });
      let sortedByNumberOfMembers = _.sortBy(conversationsWithClient, 'number_of_conversation_members');
      let conversationToSelect = sortedByNumberOfMembers.length ? _.first(sortedByNumberOfMembers) : null;

      this.selectConversation(conversationToSelect);
    },

    // reloadConversations() {
    //   let vm = this;
    //   vm.isLoadingConversationsSilently = true;
    //   vm.serverErrors = [];
    //   conversations.loadConversationsForJob(this.selectedJob.id).then(r => {
    //     vm.isLoadingConversationsSilently = false;
    //     vm.conversations = r.data;
    //     if (vm.selectedConversation) {
    //       let selectedConversationReloaded = _.find(vm.conversations, {id: vm.selectedConversation.id});
    //       if (selectedConversationReloaded) {
    //         vm.selectedConversation = selectedConversationReloaded;
    //       } else {
    //         vm.selectedConversation = null;
    //       }
    //     }
    //   }).catch(e => {
    //     console.log(e);
    //   });
    // },

    // loadConversations() {
    //   let vm = this;
    //   vm.isLoadingConversations = true;
    //   vm.serverErrors = [];
    //   vm.conversations = [];
    //   vm.selectedConversation = null;
    //   conversations.loadConversationsForJob(this.selectedJob.id).then(r => {
    //     vm.isLoadingConversations = false;
    //     vm.conversations = r.data;
    //     let firstConversation = _.first(vm.conversations);
    //     if (firstConversation) {
    //       vm.selectedConversation = firstConversation;
    //     } else {
    //       vm.selectedConversation = null;
    //     }
    //   }).catch(e => {
    //     console.log(e);
    //     vm.setAndNotifyErrors(e, 'Error loading conversations')
    //   })
    // },

    selectFirstConversationIfNotMobile() {
      let vm = this;
      if (this.isMobile) {
        return false;
      }
      let firstConversation = _.first(vm.conversations);
      if (firstConversation) {
        // vm.selectedConversation = firstConversation;
        vm.selectConversation(firstConversation);
      } else {
        vm.selectConversation(null);
        // vm.selectedConversation = null;
      }
    },

    selectConversation(conversation) {
      this.selectedConversation = conversation;
      if (conversation) {
        this.viewLayer = 3;
      } else {
        this.viewLayer = 2;
      }
    },

    handleQuoteAccepted(quote) {
      // this.selectedJob.accepted_job_quote_id = quote.id;
      // this.selectedJob.status = 1;
      this.selectedSection = 'messaging';
      // this.loadConversations();
      console.log('Accepted quote ' + quote.id);
    },

    mobileGoBack() {
      this.viewLayer -= 1;
    }
  }
}
</script>

<style lang="scss">
.messaging {
  height: 100%;
  max-height: 100%;

  .messaging-wrapper {
    //padding-top: 75px;
    width: 100%;
    padding-top: 55px;
    height: 100%;
    overflow-y: hidden;

    // Hide scrollbar
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none;  /* Firefox */

    .flex-wrapper {
      display: flex;
      height: 100%;

      .job-sidebar {
        min-width: 16%;
        width: 16%;
        max-width: 16%;

        &.is-mobile {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          flex-grow: 1;
        }
      }

      .conversations-sidebar {
        min-width: 23%;
        width: 23%;
        max-width: 23%;

        &.is-mobile {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          flex-grow: 1;
        }
      }
    }
  }

  .message {
    //background: #fd5631;
    //color: #fff;
  }
}
</style>