<template>
  <div class="p-3 activity-wrapper">
    <timeline v-if="job" :no-links="true"
              :key="reloadKey"
              :limit="null" :job-id="job.id"></timeline>
  </div>
</template>

<script>
import Timeline from "../../../components/Timeline";

export default {
  name: "Activity.vue",
  components: {Timeline},
  data() {
    return {
      reloadKey: 1
    }
  },
  props: ['job'],
  watch: {
    job() {
      this.reloadKey += 1;
    }
  }
}
</script>

<style scoped lang="scss">
.activity-wrapper {
  overflow-y: scroll;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>