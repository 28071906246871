<template>
  <div>
    <div v-if="conversations.length" class="toggle" @click.prevent="toggle" :class="{'bg-primary text-white': !hasEnabledConversations}">
<!--      <span v-if="hasUnreadGroupChats" class="fw-bold me-2">Unread:</span>-->
            <span v-if="hasUnreadGroupChats && !expanded" class="badge bg-dark me-1">Unread</span>

      <span v-if="hasEnabledConversations">
        {{ expanded ? 'Hide group chats' : 'Show group chats' }}
      </span>
      <span v-if="!hasEnabledConversations">
        {{ expanded ? 'Hide group chats' : 'Create group chat' }}
      </span>

      <i v-if="expanded" class="ms-2 fi-chevron-up opacity-80"></i>
      <i v-else class="ms-2 fi-chevron-down opacity-80"></i>

<!--      <span v-if="hasUnreadGroupChats" class="badge bg-dark ms-3">Unread</span>-->
    </div>
    <div class="expanded" v-if="expanded">
      <conversation-selector-entry
          :selected-conversation="selectedConversation"
          v-for="conversation in conversations"
          :conversation="conversation"
          @select-conversation="() => selectConversation(conversation)"
          :key="conversation.id"></conversation-selector-entry>
    </div>
  </div>
</template>

<script>
import ConversationSelectorEntry from "./ConversationSelectorEntry";
import {mapGetters} from "vuex";

const _ = require('lodash');

export default {
  name: "GroupChatsWrapper",
  props: ['conversations', 'selectedConversation'],
  components: {ConversationSelectorEntry},
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },

    selectConversation(conversation) {
      this.$emit('select-conversation', conversation);
    },

    isConversationUnread(conversation) {
      let myConversationMember = _.find(conversation.conversation_members, conversationMember => {
        return conversationMember.user_role_id === this.userRole.id;
      });

      let lastHumanActivity = conversation.last_human_activity;

      // If no last human activity, let's default it to the conversation created at date
      if (!lastHumanActivity) {
        lastHumanActivity = conversation.created_at;
      }

      // Let's now get my conversation member
      let lastReadAt = (myConversationMember && myConversationMember.last_read_at) ? myConversationMember.last_read_at : null;

      // If I haven't read the conversation, then it's unread if there's been LHA that isn't the convo being created
      if (!lastReadAt) {
        if (conversation.last_human_activity == conversation.created_at) {
          return false ;
        }
        return true;
      }

      // If we have both timestamps then let's compare
      let lastHumanActivityMoment = window.moment(lastHumanActivity);
      let lastReadAtMoment = window.moment(lastReadAt);

      return lastHumanActivityMoment.isAfter(lastReadAtMoment);
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    hasEnabledConversations() {
      let enabledConversations = _.filter(this.conversations, convo => {
        return convo.enabled;
      });
      return enabledConversations.length > 0;
    },

    hasUnreadGroupChats() {
      let hasUnread = false;

      let enabledConversations = _.filter(this.conversations, convo => {
        return convo.enabled;
      });

      _.each(enabledConversations, conversation => {
        let isUnread = this.isConversationUnread(conversation);
        if (isUnread) {
         hasUnread = true;
        }
      });

      return hasUnread;
    }
  }
}
</script>

<style scoped lang="scss">
.toggle {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  background: rgba(239, 236, 243, 0.6);
  border-radius: 10px;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background: #efecf3;
  }
}

.bg-primary {
  background: rgb(var(--primary-colour)) !important;
}

.badge {
  font-size: 11px;
  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
</style>